import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import StorageProvider from "framework/src/StorageProvider.web";
import {MenuItem} from "@material-ui/core";
import React from "react";

export interface FormFieldObject {
  name: string;
  type: "text" | "select";
  label: string;
  isDisabled: boolean;
  options?: string[];
}
export interface MemoItmeFormFieldObject {
  name: string;
  type: "text" | "select";
  label: string;
  isDisabled: boolean;
  option?: string[];
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  pdfParseData: any;
  handleFieldChange: any;
  handleCancelChange: any;
  emptyFieldList: string[];
  memoItemsEmptyFieldList: string[];
  handleSubmitNewMemoData: any;
  removeMemoItemsById:any
  memoItemsFormContent:any
  locationOption:{
    id: number,
    internal_id: string,
    name: string,
    warehouse_code: string,
    inventory_status: string,
    location_code: string
  }[];
  expenseOption:{
    id:number,
    number_and_account:string
  }[];
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  PPNOptions:{
    id: number,
    internal_id: string,
    tax_name: string,
    tax_rate: number
  }[];
  currencyOption:{
    id: number,
    internal_id: string,
    currency_name: string
  }[];
  WHTOptions:{
    id: number,
    internal_id: string,
    wh_tax_rate:number,
    wh_tax_name_description: string
  }[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class DynamicFormController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getPPNId: string = "";
  getCurrencyId: string = "";
  getWHTRateId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      PPNOptions:[],
      currencyOption:[],
      WHTOptions:[]
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.apiCallHandler(configJSON.getPPNEndPoint,(apiCallId) => {this.getPPNId = apiCallId});
    this.apiCallHandler(configJSON.getCurrencyEndPoint,(apiCallId) => {this.getCurrencyId = apiCallId});
    this.apiCallHandler(configJSON.getWHTRateEndPoint,(apiCallId) => {this.getWHTRateId = apiCallId});
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    this.receiveDropdown(message);
  }

  receiveDropdown = (message: Message) => {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        this.apiResponseHandller(apiRequestCallId, responseJson);
      }
    }
  };
  apiResponseHandller = (apiRequestCallId: string,responseJson: any) => {
    switch (apiRequestCallId) {
        case this.getPPNId:
          this.handlePPNResponseCall(responseJson);
        break;
        case this.getCurrencyId:
          this.handleCurrencyResponseCall(responseJson);
        break;
        case this.getWHTRateId:
          this.handleWHTResponseCall(responseJson);
        break;
      default:
        break;
    }

  }

  handleFieldChange = (fieldName: any, value: any, memoItemIndex?: number) => {
    if(fieldName === "apply_wh_tax?") {
      const newVal = value === "True" ? true : false
      this.props.handleFieldChange(fieldName, newVal, memoItemIndex);
      return false
   }
    this.props.handleFieldChange(fieldName, value, memoItemIndex);
  };
  apiCallHandler = async(apiEndpoint:string,onSuccess: (arg: string) => void) =>{
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const apiCallId = requestMessage.messageId;
    onSuccess(apiCallId)
    const header = {
      token: await StorageProvider.get("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handlePPNResponseCall = (responseJson:any) => {
    if(!responseJson.errors){
      this.setState({
        PPNOptions:responseJson
      })
    }
  }
  handleCurrencyResponseCall = (responseJson:any) => {
    if(!responseJson.errors){
      this.setState({
        currencyOption:responseJson
      })
    }
  }
  handleWHTResponseCall = (responseJson:any) => {
    if(!responseJson.errors){
      this.setState({
        WHTOptions:responseJson
      })
    }
  }
  renderFormFieldOptions(name:string) {
    switch (name) {
        case 'location_id':
            return this.props.locationOption?.map(option => (
                <MenuItem key={option.id} value={option.id}>
                    {option.name}
                </MenuItem>
            ));
        case 'ppn_rate_id':
            return this.state.PPNOptions?.map(option => (
                <MenuItem key={option.id} value={option.id}>
                    {option.tax_name}
                </MenuItem>
            ));
        case 'currency_id':
            return this.state.currencyOption?.map(option => (
                <MenuItem key={option.id} value={option.id}>
                    {option.currency_name}
                </MenuItem>
            ));
        case 'wh_tax_code_id':
            return this.state.WHTOptions?.map(option => (
                <MenuItem key={option.id} value={option.id}>
                    {option.wh_tax_name_description}
                </MenuItem>
            ));
        default:
            return null;
    }
  }

  handleValues = (formField :FormFieldObject, fieldValue: string | boolean | number) => {
    if(formField.name === "apply_wh_tax?") {
       const newVal = fieldValue ? "True" : "False"
       return newVal
    } else {
       return fieldValue
    }
  }
  // Customizable Area End
}
