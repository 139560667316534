import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

export interface PdfObject{
  id:number;
  name:string;
  size:string;
  url:string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  pdfURL:PdfObject[]
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedPdf:any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PDFPreviewController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getUploadedPDFParseData:string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      selectedPdf:''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.setState({
      selectedPdf:this.props.pdfURL[0].url
    })
  }

  handlePdfSelect(e:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>){
    this.setState({
      selectedPdf:e.target.value
    })
  }

  checkFileType = (fileName:string) => {
    const fileNamePart = fileName.split('.')
    const fileExtension = fileNamePart[fileNamePart.length - 1]
    return fileExtension
  }
  // Customizable Area End
}
