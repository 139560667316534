Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.postAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cffetchdetailsfrominvoicetemplate";
exports.labelBodyText = "cffetchdetailsfrominvoicetemplate Body";
exports.uploadedPDFParseData = "bx_block_cffetchdetailsfrominvoicetemplate/parse_invoices";
exports.getAPiMethod = "GET";
exports.putAPiMethod = "PUT";
exports.deleteAPiMethod = "DELETE";
exports.btnExampleTitle = "CLICK ME";
exports.emptyFiledsErrorMessage= "Please ensure all fields are filled."
exports.totalShouldBeHundredMessage= "Please ensure total category shares should be 100%."
exports.getLocationEndPoint = "bx_block_data_import_export/locations";
exports.getExpenseEndPoint = "bx_block_data_import_export/expense_coas";
exports.getPPNEndPoint = "ppn_rate";
exports.getCurrencyEndPoint = "bx_block_data_import_export/currencies";
exports.getWHTRateEndPoint = "wht_rate";
// Customizable Area End