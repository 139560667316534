import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import StorageProvider from "framework/src/StorageProvider.web";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  contentType: string;
  popoverAnchor: any;
  isChangePasswordDialogOpen: boolean;
  updatePasswordType: string;
  nameInitial:string;
  selectedDocumentId:string;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.DashboardDataInfo),
      getName(MessageEnum.NavigationPropsMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationImportExportDataMessage),
    ];

    this.state = {
      contentType: "",
      popoverAnchor: null,
      isChangePasswordDialogOpen: false,
      updatePasswordType: "",
      nameInitial:'',
      selectedDocumentId:''
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getDataFromLocalStorage();
    // Customizable Area End
  }
  // Customizable Area Start

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      let contentType = message.getData(getName(MessageEnum.DashboardDataInfo));
      if(contentType) this.setState({contentType})
    }
    // Customizable Area End
  }

  getDataFromLocalStorage = async () => {
    const isFirstLogin = localStorage.getItem("first_login");
    const nameInitial = localStorage.getItem("name_intial");
    if(nameInitial){
      this.setState({
        nameInitial:nameInitial
      })
    }
    if (isFirstLogin === "true") {
      this.setState({
        isChangePasswordDialogOpen: true,
        updatePasswordType: "reset",
      });
    }
    const token = localStorage.getItem("token")
    if (!token) {
      this.props.navigation.navigate('EmailAccountRegistration')
    } else {
      this.setState({
        contentType:"uploadDocument"
      })
    }
  };
  
  handleChangePasswordDialogClose = () => {
    this.setState({
      isChangePasswordDialogOpen: false,
    });
  };

  handleMyAccountClick = () => {
    this.setState({
      contentType: "profileSetting",
      popoverAnchor: null,
    });
  };

  handlePasswordChangeClick = () => {
    this.setState({
      isChangePasswordDialogOpen: true,
      updatePasswordType: "change",
    });
  };

  handleLogoutClick = async() => {
    await StorageProvider.remove('token');
    await StorageProvider.remove('name_intial');
    await StorageProvider.remove('first_login');
    const msg: Message = new Message(getName(MessageEnum.NavigateEmailSignUpMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
    this.send(msg);
  };

  handleContentTypeSwitch = (type:string) => {
    this.setState({
      contentType:type
    })
  }

  handleSetSelectedDocId = (documentId:string) => {
    this.setState({
      selectedDocumentId:documentId
    })
  }
  // Customizable Area End
}
