import React from "react";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
  Grid,
  TableRow,
  TableHead,
  Paper,
  TableCell,
  TableContainer,
  Table,
  TableBody,
  Typography,
  TextField,
  InputAdornment,
  Popover,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Box,
  withStyles
} from "@material-ui/core";
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import GetAppIcon from "@material-ui/icons/GetApp";
import Pagination from "@material-ui/lab/Pagination";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import RefreshIcon from "@material-ui/icons/Refresh";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./reactDatePicker.css";

const billTabletheme = createTheme({
  palette: {
    primary: {
      main: "#001E62",
      contrastText: "#001E62"
    }
  },
  typography: {
    h5: {
      fontWeight: 700
    },
    body2: {
      fontWeight: 600,
      margin: "10px 0px",
      marginTop: 25
    },
    subtitle1: {
      fontSize: "0.8rem"
    },
    fontFamily: "Graphik"
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        borderRadius: 8
      }
    },
    MuiPaper: {
      root: {
        borderRadius: 8
      }
    },
    MuiTableCell: {
      root: {
        fontWeight: 300
      },
      head: {
        color: "#001E63"
      }
    },
    MuiList: {
      root: {
        margin: 0,
        padding: "5px"
      }
    },
    MuiListItem: {
      root: {
        "&:hover": {
          backgroundColor: "#e8efff"
        },
        borderRadius: "8px",
        cursor: "pointer",
        marginBottom: 4
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 0
      }
    },
    MuiPopover: {
      paper: {
        borderRadius: "8px"
      }
    }
  }
});

const BlueCheckbox = withStyles({
  root: {
    color: "#64748B",
    "&$checked": {
      color: "#00C3E6"
    },
    borderRadius: 6
  },
  checked: {}
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

const BlueButton = withStyles({
  root: {
    backgroundColor: "#00C3E6",
    color: "#FFF",
    marginLeft: 4,
    borderRadius: 8,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#00C3E6"
    }
  }
})(Button);

// Customizable Area End

import DashboardTableController, {
  configJSON
} from "./DashboardTableController";
export default class DashboardTable extends DashboardTableController {
  // Customizable Area Start
  // Customizable Area End

  render() {
    const {
      popoverAnchor,
      tempDataSelectionArray,
      dateRange,
      dashboardTableData,
      totalPages
    } = this.state;
    return (
      // Customizable Area Start
      <ThemeProvider theme={billTabletheme}>
        <Typography color="primary" variant="h5">
          Dashboard
        </Typography>
        <Grid container spacing={2} style={webStyle.filterContainer}>
          {/* Search Bar */}
          <Grid item xs={12} sm={4} md={6}>
            <TextField
              data-test-id="searchInput"
              variant="outlined"
              size="small"
              color="primary"
              placeholder="Search"
              type="text"
              style={{ backgroundColor: "#FFF" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
              onChange={e => this.updateSearchText(e.target.value)}
            />
          </Grid>
          {/* Export Button */}
          <Grid item xs={12} sm={2} md={1}>
            <Button
              data-test-id="exportButton"
              variant="contained"
              startIcon={<GetAppIcon />}
              size="large"
              fullWidth
              style={{ ...webStyle.exportButton, textTransform: "none" }}
              onClick={this.handleExportButtonClick}
            >
              Export
            </Button>
          </Grid>
          {/* Data Selection */}
          <Grid item xs={12} sm={3} md={2}>
            <Button
              data-test-id="dataSelectionButton"
              variant="contained"
              endIcon={<ExpandLessIcon />}
              size="large"
              fullWidth
              style={{ ...webStyle.exportButton, textTransform: "none" }}
              onClick={e => this.setState({ popoverAnchor: e.currentTarget })}
            >
              Data Selection
            </Button>
            <Popover
              id="popover"
              open={Boolean(popoverAnchor)}
              anchorEl={popoverAnchor}
              onClose={() => this.setState({ popoverAnchor: null })}
              style={{
                borderRadius: 8
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center"
              }}
            >
              <List component="nav" aria-label="main mailbox folders">
                {configJSON.tableHeader.map(
                  (headerContent: string, index: number) => {
                    const labelId = `checkbox-list-label-${index}`;
                    return (
                      <ListItem
                        data-test-id={`dataSelectionListItem${index}`}
                        key={headerContent}
                        onClick={() => this.handleDataSelection(headerContent)}
                        style={{
                          backgroundColor:
                            tempDataSelectionArray.indexOf(headerContent) !== -1
                              ? "#e8efff"
                              : ""
                        }}
                      >
                        <ListItemIcon>
                          <BlueCheckbox
                            edge="start"
                            checked={
                              tempDataSelectionArray.indexOf(headerContent) !==
                              -1
                            }
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </ListItemIcon>
                        <ListItemText primary={headerContent} />
                      </ListItem>
                    );
                  }
                )}
              </List>
              <Box
                style={{
                  display: "flex",
                  padding: 4,
                  justifyContent: "space-between"
                }}
              >
                <IconButton
                  data-test-id="dataSelectionReset"
                  onClick={this.handleResetDataSelection}
                  style={webStyle.resetIconButton}
                >
                  <RefreshIcon />
                </IconButton>
                <BlueButton
                  data-test-id="dataSelectionSave"
                  variant="contained"
                  size="large"
                  fullWidth
                  onClick={this.handleDataSelectionSaveClick}
                >
                  Save
                </BlueButton>
              </Box>
            </Popover>
          </Grid>
          {/* Date Range Picker */}
          <Grid item xs={12} sm={3} md={3}>
            <DatePicker
              data-test-id="dateRangePicker"
              selectsRange={true}
              startDate={dateRange[0]}
              endDate={dateRange[1]}
              onChange={this.handleDateRangeChange}
            />
          </Grid>
        </Grid>
        <TableContainer component={Paper} style={webStyle.tableContainer}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow style={webStyle.tableRow}>
                <TableCell colSpan={2} align="center" />
                {this.filterTableHeader().map((header: string) => (
                  <TableCell key={header} colSpan={2} align="center">
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableHead>
              <TableRow style={webStyle.tableSubHeaderRow}>
                <TableCell colSpan={2} align="center">
                  B/L number
                </TableCell>
                {this.filterTableHeader().map(() => {
                  return ["Invoice Number", "Base Amount"].map(
                    (subHeader: string, index: number) => (
                      <TableCell
                        style={webStyle.subHeader}
                        key={index}
                        align="center"
                      >
                        {subHeader}
                      </TableCell>
                    )
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(dashboardTableData).map((blKey, blIndex) =>
                dashboardTableData[blKey].map(
                  (colItem: any, colIndex: number) => (
                    <TableRow
                      key={`${blKey}-${colIndex}`}
                      style={{
                        backgroundColor: blIndex % 2 === 0 ? "#E8EFFF" : ""
                      }}
                    >
                      {colItem.map((rowItem: any, rowIndex: number) => (
                        <React.Fragment
                          key={`${blKey}-${colIndex}-${rowIndex}`}
                        >
                          {rowItem.bl_no_total ? (
                            <TableCell
                              style={{ fontWeight: 500 }}
                              colSpan={2}
                              align="center"
                            >
                              {rowItem.bl_no_total}
                            </TableCell>
                          ) : (
                            <>
                              <TableCell
                                style={{
                                  color: rowItem.mark_red ? "#D91F06" : "",
                                  fontWeight:
                                    dashboardTableData[blKey].length - 1 ===
                                    colIndex
                                      ? 500
                                      : 300
                                }}
                                align="center"
                              >
                                {rowItem.invoice_no}
                              </TableCell>
                              <TableCell
                                style={{
                                  color: rowItem.mark_red ? "#D91F06" : "",
                                  fontWeight:
                                    dashboardTableData[blKey].length - 1 ===
                                    colIndex
                                      ? 500
                                      : 300
                                }}
                                align="center"
                              >
                                {rowItem.base_amount}
                              </TableCell>
                            </>
                          )}
                        </React.Fragment>
                      ))}
                    </TableRow>
                  )
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Grid container justifyContent="flex-end">
          <Grid item xs={12} md={3}>
            <Pagination
              count={totalPages}
              onChange={this.handlePaginationChange}
              variant="outlined"
              shape="rounded"
            />
          </Grid>
        </Grid>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  filterContainer: {
    padding: "15px 0px"
  },
  exportButton: {
    borderRadius: 8,
    backgroundColor: "#FFF",
    height: 40
  },
  paginationContainer: {
    display: "flex",
    width: "100%",
    alignItems: "flex-end"
  },
  tableContainer: { maxHeight: "70vh" },
  tableRow: { backgroundColor: "#D9F0F7", color: "#001E63" },
  tableSubHeaderRow: { backgroundColor: "#F1F5F7", color: "#001E63" },
  subHeader: { fontSize: 12, fontWeight: 400 },
  resetIconButton: { borderRadius: 8, border: "1px solid #00C3E6" }
};
// Customizable Area End
