import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  TextField,
  MenuItem,
  Card,
  CardContent,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import DocViewer, { DocViewerRenderers, PDFRenderer } from "react-doc-viewer";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import CloseIcon from "@material-ui/icons/Close";
import BackupIcon from "@material-ui/icons/Backup";
import BackupOutlinedIcon from "@material-ui/icons/BackupOutlined";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import {
  FileObject,
  BillTypeObject,
  Pdf,
} from "./DocumentOpenerCommonController";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// Customizable Area End

import DocumentOpenerController from "./DocumentOpenerController.web";
import { Props, configJSON } from "./DocumentOpenerCommonController";

export default class DocumentOpener extends DocumentOpenerController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      filesArray,
      expanded,
      isMessageDialogOpen,
      errorType,
      selectedFileId,
      selectedPDFId,
      billTypes,
      selectedBillType
    } = this.state;
    // Customizable Area End
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Dialog
          maxWidth="md"
          open={isMessageDialogOpen}
          PaperProps={{
            style: { borderBottomRightRadius: "32px", width: 460 },
          }}
          onClose={() => this.handleErrorDialogClose()}
        >
          <IconButton
            edge="end"
            color="inherit"
            onClick={() => this.handleErrorDialogClose()}
            aria-label="close"
            style={webStyle.closeIcon}
          >
            <CloseIcon />
          </IconButton>
          <DialogTitle>
            <Box style={webStyle.dialogTitleContainer}>
              {errorType === "upload" && (
                <CancelIcon style={webStyle.cancelIcon} />
              )}
              <Typography variant="body2">
                {errorType === "upload"
                  ? "Only PDF and Images are supported."
                  : "Are you sure you want to delete this file?"}
              </Typography>
            </Box>
          </DialogTitle>
          {errorType === "delete" && (
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                This action can't be undone once done.
              </DialogContentText>
            </DialogContent>
          )}
          <DialogActions style={webStyle.dialogAction}>
            <Button
              style={{ ...webStyle.dialogActionButton, textTransform: "none" }}
              onClick={() =>
                errorType === "upload"
                  ? this.handleErrorDialogClose()
                  : this.deleteDocumentAPICall(selectedFileId, selectedPDFId)
              }
              data-test-id="deleteConfirm"
            >
              {errorType === "upload" ? "Okay" : "Delete"}
            </Button>
          </DialogActions>
        </Dialog>
        <Typography color="primary" variant="h5">
          Upload
        </Typography>
        <Grid container justifyContent="center" style={webStyle.gridContainer}>
          <Grid item xs={false} md={1} />
          <Grid item xs={12} md={11}>
            <TextField
              data-test-id={`selectBillType`}
              select
              variant="outlined"
              size="small"
              style={webStyle.fileTypeSelect}
              label="Select Bill Type"
              value={selectedBillType}
              onChange={(e) =>
                this.handleSelectBillType(e)
              }
              SelectProps={{
                style: {
                  backgroundColor:"#E8EFFF",
                },
              }}
            >
              {billTypes.map((type: BillTypeObject) => (
                <MenuItem key={type.id} value={type.id}>
                  {type.attributes.name}
                </MenuItem>
              ))}
            </TextField>
            <Box
              data-test-id="dragAndDrop"
              onDrop={(e) => this.handleDrop(e, this.state.selectedBillType)}
              onDragOver={this.handleDragOver}
              style={{ ...webStyle.uploadMedialContainer, textAlign: "center" }}
            >
              <BackupIcon style={webStyle.backupIcon} />
              <Typography variant="body1">Upload media</Typography>
              <Typography variant="subtitle1" style={webStyle.uploadTitle}>
                Drag and drop the files here
              </Typography>
              <Box>
                <label
                  htmlFor="file-upload"
                  style={webStyle.fileUploadContainer}
                >
                  <AddCircleOutlineIcon />
                  Browse file to upload
                </label>
                <input
                  data-test-id="fileUpload"
                  id="file-upload"
                  type="file"
                  multiple
                  accept=".pdf, .png, .jpeg, .jpg"
                  onChange={(e) => this.handleFileUpload(e, this.state.selectedBillType, "", false)}
                  style={webStyle.fileInput}
                />
                <Box />
              </Box>
            </Box>
            <Box style={webStyle.billListContainer}>
              <Typography variant="body2" style={webStyle.billText}>
                BILLS
              </Typography>
            </Box>
            <Box>
              {filesArray.map((file: FileObject, index: number) => {
                return (
                  <Accordion
                    variant={"elevation"}
                    elevation={3}
                    key={index}
                    expanded={expanded === `panel${index}`}
                    onChange={this.handleAccordionChange(`panel${index}`)}
                    style={
                      expanded === `panel${index}`
                        ? webStyle.expandedAccordion
                        : webStyle.accordion
                    }
                  >
                    <AccordionSummary
                      style={
                        expanded === `panel${index}`
                          ? webStyle.expandedAccordionSummary
                          : {}
                      }
                      expandIcon={
                        file.attributes.pdfs.length > 1 ? (
                          <IconButton>
                            <ExpandMoreIcon />
                          </IconButton>
                        ) : (
                          <IconButton>
                            <DeleteOutlineIcon
                              onClick={(e) =>
                                this.handleClickDeleteIcon(
                                  e,
                                  file.id,
                                  file.attributes.pdfs[0].id
                                )
                              }
                              style={webStyle.deletIcon}
                              data-test-id={`delete${index}`}
                            />
                          </IconButton>
                        )
                      }
                    >
                      <Grid
                        container
                        alignItems="center"
                        style={webStyle.cardGridContainer}
                        data-test-id={`billContainer${index}`}
                      >
                        <Grid
                          item
                          xs={3}
                          sm={1}
                          style={webStyle.actionIconContainer}
                        >
                          <Box>
                            <label
                              htmlFor={`multiple-file-upload${file.id}`}
                              style={webStyle.multipleUpload}
                            >
                              <BackupOutlinedIcon color="primary" />
                            </label>
                            <input
                              data-test-id={`multipleFileUpload${index}`}
                              id={`multiple-file-upload${file.id}`}
                              type="file"
                              multiple
                              accept=".pdf, .png, .jpeg, .jpg"
                              onChange={(e) =>
                                this.handleFileUpload(e, "", file.id, true)
                              }
                              style={webStyle.fileInput}
                            />
                          </Box>
                        </Grid>
                        <Grid
                          item
                          xs={9}
                          sm={5}
                          md={8}
                          data-test-id={`uploadedPdfFile${index}`}
                          onClick={(e) =>
                            file.attributes.pdfs.length === 1
                              ? this.handleDocumentClick(
                                  file.id
                                )
                              : ""
                          }
                        >
                          <Box style={webStyle.fileName}>
                            <Typography color="primary" variant="body1">
                              {file.attributes.pdfs[0].name}
                            </Typography>
                            <Typography
                              variant="body1"
                              style={webStyle.fileSize}
                            >
                              {file.attributes.pdfs[0].size}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={3}
                          onClick={(event) => event.stopPropagation()}
                          data-test-id={`selectBillTypeContainer${index}`}
                        >
                          <Chip
                            style={expanded === `panel${index}` ? webStyle.exapandedViewChip : {}}
                            label={file.attributes.bill_type}
                          />
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    {file.attributes.pdfs.length > 1 ? (
                      <AccordionDetails>
                        {file.attributes.pdfs.map((pdf: Pdf, idx) => (
                          <Card variant="outlined" key={pdf.id}>
                            <CardContent style={webStyle.childCard}>
                              <Grid container justifyContent="space-between">
                                <Grid
                                  item
                                  xs={9}
                                  sm={7}
                                  md={11}
                                  data-test-id={`uploadedPdfFileMultiple${idx}`}
                                  onClick={(e) =>
                                    this.handleDocumentClick(
                                      file.id
                                    )
                                  }
                                >
                                  <Box style={webStyle.fileName}>
                                    <Typography color="primary" variant="body1">
                                      {pdf.name}
                                    </Typography>
                                    <Typography
                                      variant="body1"
                                      style={webStyle.fileSize}
                                    >
                                      {pdf.size}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={3}
                                  sm={5}
                                  md={1}
                                  style={{
                                    display: "flex",
                                    justifyContent: "end",
                                  }}
                                >
                                  <IconButton>
                                    <DeleteOutlineIcon
                                      onClick={(e) =>
                                        this.handleClickDeleteIcon(
                                          e,
                                          file.id,
                                          pdf.id
                                        )
                                      }
                                      style={webStyle.deletIcon}
                                      data-test-id={`deleteFromMultiple${idx}`}
                                    />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        ))}
                      </AccordionDetails>
                    ) : (
                      <></>
                    )}
                  </Accordion>
                );
              })}
            </Box>
          </Grid>
        </Grid>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#001E62",
      contrastText: "#001E62",
    },
  },
  typography: {
    h5: {
      fontWeight: 700,
    },
    body2: {
      fontSize: 18,
      fontWeight: 700,
    },
    subtitle1: {
      fontSize: "0.8rem",
    },
    fontFamily: "Graphik",
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        borderRadius: 10,
        "& $notchedOutline": {
          borderColor: "transparent",
        },
        "&:hover $notchedOutline": {
          borderColor: "transparent",
        },
        "&$focused $notchedOutline": {
          borderColor: "transparent",
        },
      },
    },
    MuiInputLabel: {
      root: {
        "&$shrink": {
          display: "none",
        },
        color: "#001E63",
      },
      shrink: {
        transform: "translate(0, 1.5px) scale(0.75)",
      },
    },
    MuiList: {
      root: {
        margin: 0,
        padding: "5px",
      },
    },
    MuiListItem: {
      root: {
        "&$selected, &$selected:hover": {
          backgroundColor: "#e8efff",
        },
        "&:hover": {
          backgroundColor: "#e8efff",
        },
        borderRadius: "8px",
        cursor: "pointer",
      },
    },
    MuiPopover: {
      paper: {
        borderRadius: "8px",
      },
    },
    MuiSelect: {
      selectMenu: {
        borderRadius: 10,
      },
      select: {
        "&:focus": {
          borderRadius: 10,
        },
      },
    },
    MuiMenuItem: {
      root: {
        borderRadius: 10,
        "&$selected": {
          backgroundColor: "#e8efff",
        },
        "&:hover": {
          backgroundColor: "#e8efff",
        },
      },
    },
    MuiAccordion: {
      root: {
        "&::before": {
          backgroundColor: "transparent",
        },
      },
      rounded: {
        borderRadius: 8,
        "&:first-child": {
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
        },
      },
    },
    MuiAccordionSummary: {
      root: {
        marginTop: 20,
        borderRadius: 8,
        padding: 0,
      },
      content: {
        margin: 0,
      },
      expandIcon: {
        paddingRight: 15,
      },
    },
    MuiAccordionDetails: {
      root: {
        display: "block",
        padding: "0px 20px 15px",
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: 8,
        marginTop: 20,
      },
    },
    MuiIconButton: {
      edgeEnd: {
        marginRight: 5,
      },
    },
    MuiChip:{
      root:{
        borderRadius:8,
        backgroundColor:'#E8EFFF',
        color:'#001E63',
        height:40,
        fontSize:'1rem'
      }
    }
  },
});

const webStyle = {
  closeIcon: {
    margin: 0,
    marginLeft: "auto",
  },
  dialogTitleContainer: {
    color: "#001E62",
    display: "flex",
    alignItems: "center",
  },
  cancelIcon: {
    color: "#dc2626",
    marginRight: 20,
    backgroundColor: "#fee2e2",
    borderRadius: "50%",
    padding: 10,
  },
  dialogAction: {
    borderTop: "1px solid #757575",
  },
  dialogActionButton: {
    margin: 15,
    height: 50,
    borderRadius: 10,
    backgroundColor: "#00C3E6",
    color: "#fff",
  },
  gridContainer: {
    padding: 20,
  },
  uploadMedialContainer: {
    alignItems: "center",
    marginTop: "50px",
    border: "2px solid #CBD5E1",
    borderRadius: "16px",
    padding: "20px 0px",
  },
  backupIcon: {
    width: "40px",
    height: "40px",
    color: "#00C3E6",
  },
  uploadTitle: {
    fontSize: "14px",
    color: "grey",
  },
  fileUploadContainer: {
    fontSize: "15px",
    color: "#00C3E6",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    marginTop: 10,
    cursor: "pointer",
  },
  fileInput: { display: "none" },
  billListContainer: {
    padding: "10px 0px",
    borderBottom: "1px solid #e2e8f0",
  },
  billText: { color: "#64748b" },
  deletIcon: { color: "#dc2626" },
  singleFileContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  fileDetailsContainer: { display: "flex", alignItems: "center" },
  fileName: { marginLeft: "0.5rem", cursor: "pointer" },
  fileSize: { color: "#757575" },
  fileTypeSelect: { width: 200 },
  actionIconContainer: { display: "flex", justifyContent: "center" },
  childCard: {
    padding: 10,
  },
  multipleUpload: { cursor: "pointer" },
  accordion: {
    transition:"all ease-in-out"
  },
  expandedAccordion: {
    border: "2px solid #001E63",
    margin: "20px -10px",
    transition:"all ease-in-out"
  },
  expandedAccordionSummary: {
    backgroundColor: "#B2EDF7",
    borderRadius: "8px 8px 0px 0px",
    marginTop: 0,
  },
  cardGridContainer: {
    padding: 12,
  },
  exapandedViewChip: {
    backgroundColor: "transparent",
    border: "1px solid #001E63"
  }
};
// Customizable Area End
