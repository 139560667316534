import React, { useState, createContext, useEffect } from "react";
import SnackbarMessage from "./SnackBar.web";
import Loader from "./Loader.web";

export const SnackbarLoaderContext = createContext<any>(undefined);

export const SnackbarLoaderContextProvider = SnackbarLoaderContext.Provider;

const ContextProvider: React.FC = ({ children }) => {
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const setSnackbar = (message: string) => {
    setIsSnackbarOpen(true);
    setSnackbarMessage(message);
  };

  const unsetSnackbar = (event: any, reason: any) => {
    if (reason === "clickaway") {
      return;
    }
    setIsSnackbarOpen(false);
    setSnackbarMessage("");
  };

  const setLoader = () => {
    setIsLoading(true);
  };

  const unsetLoader = () => {
    setIsLoading(false);
  };

  const contextValue: any = {
    setSnackbar,
    setLoader,
    unsetLoader,
		unsetSnackbar,
		isSnackbarOpen,
		snackbarMessage,
		isLoading
  };
	
  return (
    <SnackbarLoaderContextProvider value={contextValue}>
      {children}
      <SnackbarMessage/>
      <Loader />
    </SnackbarLoaderContextProvider>
  );
};

export default ContextProvider;
