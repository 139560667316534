import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { SnackbarLoaderContext } from "./context";

const useStyles = makeStyles(() => ({
  root: {
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgb(0, 0, 0, .4)",
    zIndex: 1,
  },
  circularContainer: {
    position: "absolute",
    left: "50%",
    top: "40%",
    transform: "translate(-50%, -50%)",
  },
}));

const Loader = () => {

  const context = useContext(SnackbarLoaderContext)
  const {isLoading} = context

  const classes = useStyles();
  return (
    <>
      {isLoading && (
        <div className={classes.root}>
          <div className={classes.circularContainer}>
            <CircularProgress />
          </div>
        </div>
      )}
    </>
  );
};

export default Loader;
