Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.documentAPiEndPoint = "bx_block_documentopener2/documents";
exports.billTypeAPiEndPoint = "bx_block_categories/categories";
exports.uploadedPDFParseData = "bx_block_cffetchdetailsfrominvoicetemplate/parse_invoices";
exports.getAPiMethod = "GET";
exports.postAPiMethod = "POST";
exports.putAPiMethod = "PUT";
exports.deleteAPiMethod = "DELETE";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "DocumentOpener";
exports.labelBodyText = "DocumentOpener Body";

exports.btnExampleTitle = "CLICK ME";

exports.openFromDevice = "Open from device";
exports.openWithUrl = "Open with URL";
// Customizable Area End