import React from "react";

import {
  // Customizable Area Start
  Card,
  CardContent,
  Typography,
  Grid,
  TextField,
  MenuItem,
  Box,
  Button,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
const dynamicMasterFormContent = require("./dynamicMasterFormContent.json");

const theme = createTheme({
  palette: {
    primary: {
      main: "#001E62",
      contrastText: "#001E62",
    },
  },
  typography: {
    body2: {
      fontWeight: 600,
      margin: "10px 0px",
    },
    fontFamily: "Graphik",
  },
});

import DynamicFormController, {
  Props,
  FormFieldObject,
  MemoItmeFormFieldObject,
} from "./DynamicFormController";

// Customizable Area End

export default class DynamicForm extends DynamicFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { pdfParseData } = this.props;
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Grid container>
          <Card
            variant="elevation"
            elevation={0}
            style={{
              ...webStyle.cardStyle,
              overflowY: "scroll",
              scrollbarWidth: "none",
            }}
          >
            <CardContent style={{ padding: 30 }}>
              {dynamicMasterFormContent.map(
                (formField: FormFieldObject, index: number) => {
                  if (formField.name === "currency_exchange") {
                    return (
                      <Box key={formField.name}>
                        <Typography variant="body2" color="primary">
                          {formField.label}
                        </Typography>
                        <TextField
                          data-test-id={`formField${index}`}
                          disabled={formField.isDisabled}
                          color="primary"
                          type={formField.type}
                          variant="outlined"
                          fullWidth
                          size="small"
                          select={formField.type === "select"}
                          error={this.props.emptyFieldList.includes(formField.name)}
                         
                          onChange={(e) =>
                            this.handleFieldChange(formField.name, e.target.value)
                          }
                          value={
                            pdfParseData[formField.name] !== null
                              ? pdfParseData[formField.name]
                              : ""
                          }
                        >
                          {formField.options &&
                            formField.options.map((item: string | number) => (
                              <MenuItem key={item} value={item}>
                                {item}
                              </MenuItem>
                            ))}
                        </TextField>
                      </Box>
                    );
                  }
                  else if (pdfParseData.hasOwnProperty(formField.name)) {
                    let jenisField = formField?.name === 'kd_jenis_transaksi' 
                    ? pdfParseData[formField?.name]?.substring(0, 3)
                    : pdfParseData[formField?.name]
                    let fieldValue = pdfParseData[formField?.name] !== null
                        ? jenisField
                        : "";
                    return (
                      // {dynamicMasterFormContent.map(
                      //   (formField: FormFieldObject, index: number) =>
                      //     pdfParseData.hasOwnProperty(formField.name) && (
                      <Box key={formField.name}>
                        <Typography variant="body2" color="primary">
                          {formField.label}
                        </Typography>
                        <TextField
                          color="primary"
                          type={formField.type}
                          data-test-id={`formField${index}`}
                          data-testID={`form-${formField.name}`}
                          variant="outlined"
                          size="small"
                          fullWidth
                          disabled={formField.isDisabled}
                          select={formField.type === "select"}
                          error={this.props.emptyFieldList.includes(
                            formField.name
                          )}
                          value={this.handleValues(formField, fieldValue)}
                          onChange={(e) =>
                            this.handleFieldChange(formField.name, e.target.value)
                          }
                        >
                          {formField.options &&
                            formField.options.map((item: string | number) => (
                              <MenuItem key={item} value={item}>
                                {item}
                              </MenuItem>
                            ))}
                            {this.renderFormFieldOptions(formField.name)}
                        </TextField>
                      </Box>
                    );
                  } else {
                    return null;
                  }
                }
              )}
              {pdfParseData.memo_items &&
                pdfParseData.memo_items.map(
                  (memoItem: any, memoIndex: number) => {
                    let memoItemTitle: string = "";
                    if (memoItem.type_split) {
                      memoItemTitle = memoItem.type_split;
                    } else {
                      memoItemTitle = `Memo Item`;
                    }
                    return (
                      <Card
                        key={memoItem.id}
                        variant="outlined"
                        style={webStyle.memoItemCard}
                      >
                        <CardContent>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography variant="h6" color="primary">
                              {`${memoItemTitle} ${memoIndex + 1}`}
                            </Typography>
                            {!memoItem.id ? (
                              <div>
                                {" "}
                                <Button
                                  variant="outlined"
                                  style={{
                                    height: 30,
                                    borderRadius: 8,
                                    color: "#00C3E6",
                                    padding: "10px 20px",
                                    marginRight: 10,
                                    textTransform: "none",
                                  }}
                                  size="small"
                                  onClick={this.props.handleSubmitNewMemoData.bind(
                                    this,
                                    memoIndex,
                                    `${memoItemTitle} ${memoIndex + 1}`
                                  )}
                                  data-test-id="formAddMemoSaveButton"
                                >
                                  Save
                                </Button>
                                <Button
                                  variant="outlined"
                                  style={{
                                    height: 30,
                                    borderRadius: 8,
                                    color: "#00C3E6",
                                    padding: "10px 20px",
                                    marginRight: 10,
                                    textTransform: "none",
                                  }}
                                  size="small"
                                  onClick={this.props.handleCancelChange.bind(
                                    this,
                                    memoIndex
                                  )}
                                  data-test-id="formCancelMemoButton"
                                >
                                  Cancel
                                </Button>
                              </div>
                            ) : (
                              <>
                                <Button
                                  variant="outlined"
                                  style={{
                                    height: 30,
                                    borderRadius: 8,
                                    color: "#00C3E6",
                                    padding: "10px 20px",
                                    marginRight: 10,
                                    textTransform: "none",
                                  }}
                                  size="small"
                                  onClick={this.props.removeMemoItemsById.bind(
                                    this,
                                    memoItem.id
                                  )}
                                  data-test-id="formDeleteMemoButton"
                                >
                                  Delete
                                </Button>
                              </>
                            )}
                          </div>
                          {this.props.memoItemsFormContent.map(
                            (
                              formField: MemoItmeFormFieldObject,
                              index: number
                            ) =>
                              memoItem.hasOwnProperty(formField.name) && (
                                <Box key={formField.name + memoItem.id}>
                                  <Typography variant="body2" color="primary">
                                    {formField.label}
                                  </Typography>
                                  <TextField
                                    data-test-id={`MemoItemFormField${memoIndex}${index}`}
                                    variant="outlined"
                                    size="small"
                                    color="primary"
                                    type={formField.type}
                                    disabled={formField.isDisabled}
                                    fullWidth
                                    select={formField.type === "select"}
                                    error={this.props.memoItemsEmptyFieldList.includes(
                                      `${formField.name}_${memoIndex}`
                                    )}
                                    value={
                                      memoItem[formField.name] !== null
                                        ? memoItem[formField.name]
                                        : ""
                                    }
                                    onChange={(e) =>
                                      this.handleFieldChange(
                                        formField.name,
                                        e.target.value,
                                        memoIndex
                                      )
                                    }
                                  >
                                    {(formField.option && formField.name !== 'expense_coa_id') &&
                                      formField.option.map(
                                        (item: string | number) => (
                                          <MenuItem key={item} value={item}>
                                            {item}
                                          </MenuItem>
                                        )
                                      )}
                                      {formField.name === 'expense_coa_id' &&
                                          this.props.expenseOption?.map(option => (
                                            <MenuItem key={option.id} value={option.id}>
                                              {option.number_and_account}
                                            </MenuItem>
                                          ))
                                      }
                                  </TextField>
                                </Box>
                              )
                          )}
                        </CardContent>
                      </Card>
                    );
                  }
                )}
            </CardContent>
          </Card>
          <Grid item xs={12} />
        </Grid>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  cardStyle: {
    backgroundColor: "#fff",
    borderRadius: "8px 8px 32px 8px",
    width: "100%",
    height: "90vh",
  },
  memoItemCard: {
    margin: "10px 0px",
  },
};
// Customizable Area End
