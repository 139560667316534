import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  isChangePasswordDialogOpen: boolean;
  handleChangePasswordDialogClose: any;
  updatePasswordType: string;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  token: string;
  validlength: boolean;
  hasUppercase: boolean;
  hasLowercase: boolean;
  hasDigit: boolean;
  oldPassword: string;
  oldPasswordError: string;
  password: string;
  confirmedPassword: string;
  isResetPasswordDialogOpen: boolean;
  confirmPasswordError: boolean;
  isSuccessAlertOpen: boolean;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class ResetPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  updatePaaswordAPICallId: string = "";
  changePaaswordAPICallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      token: "",
      validlength: false,
      hasUppercase: false,
      hasLowercase: false,
      hasDigit: false,
      oldPassword: "",
      oldPasswordError: "",
      password: "",
      confirmedPassword: "",
      isResetPasswordDialogOpen: false,
      confirmPasswordError: false,
      isSuccessAlertOpen: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.receivedata(message);
    // Customizable Area End
  }

  // Customizable Area Start
  receivedata = (message: any) => {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        this.apiResponseHandller(apiRequestCallId, responseJson);
      }
    }
  };

  apiResponseHandller = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.updatePaaswordAPICallId || apiRequestCallId === this.changePaaswordAPICallId) {
      if (!responseJson.errors && !responseJson.error) {
        this.props.handleChangePasswordDialogClose()
        this.setState({
          isSuccessAlertOpen: true,
        });
        this.handleSetLocalStorage()
      } else {
        const errMsg = responseJson.error.includes("Old password is incorrect or not present") ?
        "Incorrect old password" : "Old Password and New Password cannot be the same"
        this.setState({
          oldPasswordError: errMsg
        })
      }
    }
  };

  handleSetLocalStorage = () => {
    localStorage.clear();
    setTimeout(() => {
      const msg: Message = new Message(getName(MessageEnum.NavigateEmailSignUpMessage));
      msg.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
      this.send(msg);
    }, 2000);
  };

  handlePasswordChange = (value: string) => {
    this.setState(
      {
        password: value,
        validlength: value.length >= 8,
        hasUppercase: /[A-Z]/.test(value),
        hasLowercase: /[a-z]/.test(value),
        hasDigit: /\d/.test(value),
      },
      () => this.checkPasswordError()
    );
  };

  handleConfirmPasswordChange = (value: string) => {
    this.setState({ confirmedPassword: value }, () =>
      this.checkPasswordError()
    );
  };

  updatePasswordAPICall = () => {
    const header = {
      token: localStorage.getItem('token'),
    };

    let formdata = new FormData();
    formdata.append("password", String(this.state.password));
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updatePaaswordAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updatePassword
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodTypeGetPatch
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  changePasswordAPICall = () => {
    const header = {
      token: localStorage.getItem('token'),
    };
    let formdata = new FormData();
    formdata.append("old_password", String(this.state.oldPassword));
    formdata.append("new_password", String(this.state.password));
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.changePaaswordAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.changePassword
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodTypeGetPatch
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  checkPasswordError = () => {
    const { password, confirmedPassword } = this.state;
    const passwordError =
      password !== "" &&
      confirmedPassword !== "" &&
      password !== confirmedPassword;
    this.setState({
      confirmPasswordError: passwordError,
    });
  };

  handleCloseSuccessAlert = () => {
    this.setState({ isSuccessAlertOpen: false });
  };

  checkDisabled = () => {
    const { validlength, hasUppercase, hasLowercase, hasDigit, password, confirmedPassword} = this.state
    return (!validlength ||
    !hasUppercase ||
    !hasLowercase ||
    !hasDigit ||
    password !== confirmedPassword)
  }
}
