import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import StorageProvider from "framework/src/StorageProvider.web";
import {SnackbarLoaderContext} from "../../../components/src/context"
export interface Pdf {
  "id": number,
  "name": string,
  "size": number,
  "url": string
}
export interface FileObject {
  id: string,
  type: string,
  attributes: {
    account_id: number,
    status: string,
    created_at: string,
    updated_at: string,
    bill_type:null | string,
    pdfs:Pdf[]
  }
}
export interface BillTypeObject{
  id: string,
  type: string,
  attributes: {
    name: string,
    hidden: false,
    created_at: string,
    updated_at: string
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleContentTypeSwitch:(type:string) => void;
  handleSetSelectedDocId:(documentId:string) => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectFile: boolean;
  filesArray:FileObject[];
  expanded:string;
  isMessageDialogOpen:boolean;
  errorType:string;
  selectedFileId:string;
  selectedPDFId:number;
  billTypes:BillTypeObject[];
  selectedBillType:string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class DocumentOpenerCommonController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getBillTypesAPICallId: string = "";
  uploadDocumentAPICallId: string = "";
  getAllUploadedDocumentsAPICallId: string = "";
  deleteDocumentAPICallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      selectFile: false,
      filesArray:[],
      expanded:"",
      isMessageDialogOpen:false,
      errorType:'',
      selectedFileId:"",
      selectedPDFId:0,
      billTypes:[],
      selectedBillType:''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.receiveDataDocumentOpener(message);
    // Customizable Area End
  }

  // Customizable Area Start
  static contextType = SnackbarLoaderContext
  async componentDidMount() {
    super.componentDidMount();
    this.getAllUploadedDocumentAPICall()
    this.getBillTypesAPICall()
  }

  receiveDataDocumentOpener = (message: Message) => {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        this.apiResponseHandllerDocumentOpener(apiRequestCallId, responseJson);
      }
    }
  };

  apiResponseHandllerDocumentOpener = (apiRequestCallId: string, responseJson: any) => {
    this.context.unsetLoader && this.context.unsetLoader()
      if (apiRequestCallId === this.getBillTypesAPICallId) {
      if (!responseJson.errors) {
        this.setState({
          billTypes:responseJson.data
        })
      }
    } else if (apiRequestCallId === this.uploadDocumentAPICallId) {
      if (!responseJson.errors) {
        this.handleSetSnackbar("Documents uploaded successfully!")
        this.getAllUploadedDocumentAPICall()
        this.setState({
          selectedBillType:''
        })
      }
    } else if (apiRequestCallId === this.getAllUploadedDocumentsAPICallId) {
      if (!responseJson.errors) {
        this.setState({
          filesArray:responseJson.data
        })
      }
    } else if (apiRequestCallId === this.deleteDocumentAPICallId) {
      this.setState({
        expanded:""
      })
      this.handleSetSnackbar(responseJson.message)
      this.handleErrorDialogClose()
      this.getAllUploadedDocumentAPICall()
    }
  };

  checkFileTypeError = (files:File[]) => {
    let isFileTypeError:boolean = false
    for (const file of files) {
      const fileName:string = file.name
      const fileNamePart = fileName.split('.')
      const fileExtension = fileNamePart[fileNamePart.length - 1]
      if(fileExtension !== 'pdf' && fileExtension !== 'png' && fileExtension !== 'jpeg' && fileExtension !== 'jpg') {
        this.setState({
          isMessageDialogOpen:true,
          errorType:'upload'
        })
        isFileTypeError = true
        break
      }
    }
    return isFileTypeError
  }

  handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>, categoryId:string, documentId:string, fromUploadedFile:boolean) => {
    if(this.state.selectedBillType === "" && !fromUploadedFile){
      this.handleSetSnackbar('Please select bill type first!')
    } else if(e.target.files){
      const files:File[] = Array.from(e.target.files);
      if(this.checkFileTypeError(files)) return
      this.uploadDocumentAPICall(files, categoryId, documentId)
    }
  };

  handleDrop = (e: React.DragEvent<HTMLElement>, categoryId:string) => {
    if(this.state.selectedBillType === ""){
      this.handleSetSnackbar('Please select bill type first!')
    } else {
      e.preventDefault();
      const files:File[] = Array.from(e.dataTransfer.files);
      if(this.checkFileTypeError(files)) return
      this.uploadDocumentAPICall(files, categoryId)
    }
  };

  handleDragOver = (e: React.DragEvent<HTMLElement>) => {
    e.preventDefault();
  };

  handleErrorDialogClose = () => {
    this.setState({isMessageDialogOpen:false})
  }

  handleClickDeleteIcon = (e:React.MouseEvent<SVGSVGElement, MouseEvent>, fileId:string, pdfID:number) => {
    e.stopPropagation()
    this.setState({
      isMessageDialogOpen:true,
      errorType:'delete',
      selectedFileId:fileId,
      selectedPDFId:pdfID
    })
  }

  handleDocumentClick = (documentId:string) => {
    this.props.handleContentTypeSwitch('documentPreview')
    this.props.handleSetSelectedDocId(documentId)
  }

  handleSelectBillType = (e:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({
      selectedBillType:e.target.value
    })
  }

  getBillTypesAPICall = async () => {
    const header = {
      token: await StorageProvider.get("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBillTypesAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.billTypeAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  uploadDocumentAPICall = async (documents:File[], categoryId?:string, documentId?:string) => {
    this.handleSetLoader()
    const header = {
      token: await StorageProvider.get("token"),
    };

    let formdata = new FormData();
    for (const document of documents) {
      formdata.append("files[]", document);
    }
    if(categoryId){
      formdata.append("category_id", categoryId);
    }
    if(documentId){
      formdata.append("document_id", documentId);
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.uploadDocumentAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.documentAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getAllUploadedDocumentAPICall = async () => {
    this.handleSetLoader()
    const header = {
      token: await StorageProvider.get("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllUploadedDocumentsAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.documentAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  deleteDocumentAPICall = async (docID:string, pdfId:number) => {
    this.handleSetLoader()
    const header = {
      token: await StorageProvider.get("token"),
      "Content-Type":"application/json"
    };
    const body = {
      pdf_id:pdfId
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteDocumentAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.documentAPiEndPoint}/${docID}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleAccordionChange = (panel:string) => (event:React.ChangeEvent<{}>, isExpanded:boolean) => {
    this.setState({
      expanded:isExpanded ? panel : ''
    })
  }

  handleSetLoader = () => {
    this.context.setLoader &&  this.context.setLoader()
  }

  handleSetSnackbar = (message:string) => {
    this.context.setSnackbar && this.context.setSnackbar(message)
  }
  // Customizable Area End
}
