import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import StorageProvider from "framework/src/StorageProvider.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: string;
  emailerror: boolean;
  emailerrortext: any;
  password: any;
  passerror: boolean;
  passerrortext: any;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  validlength: boolean;
  hasUppercase: boolean;
  hasLowercase: boolean;
  hasDigit: boolean;
  screenContentType: string;
  confirmPasswordError: boolean;
  resetPasswordToken: string;
  rememberMe: boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";
  loginAPICallID: string = "";
  sendEmailRecoveryLinkAPICallID: string = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  currentCountryCode: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      email: "",
      emailerrortext: "",
      emailerror: false,
      password: "",
      passerrortext: "",
      passerror: false,
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      validlength: false,
      hasUppercase: false,
      hasLowercase: false,
      hasDigit: false,
      screenContentType: "login",
      confirmPasswordError: false,
      resetPasswordToken: "",
      rememberMe:false
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = /\w+/;
    this.emailReg = /\w+/;

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.restAPIReceive(message);
    this.navigationPayLoadReceive(message);
    this.countryCodeReceive(message);
    // Customizable Area End
  }

  // Customizable Area Start

  navigateToDashboard = () => {
    const msg: Message = new Message(getName(MessageEnum.NavigationDashboardMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
    this.send(msg);
  }

  async componentDidMount() {
    const token = await StorageProvider.get("token")
    super.componentDidMount();
    if (token) {
      this.navigateToDashboard();
    }
    const savedEmail = await StorageProvider.get('email');
    const savedPassword = await StorageProvider.get('password');
    if(savedEmail && savedPassword){
      this.setState({
        email: savedEmail,
        password: savedPassword,
        rememberMe: true
      });
    }
  }

  restAPIReceive = (message: any) => {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        this.APIResponse(apiRequestCallId, responseJson);
      }
    }
  };

  APIResponse = async (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.validationApiCallId) {
      this._check(responseJson);
    } else if (apiRequestCallId === this.loginAPICallID) {
      if (!responseJson.errors) {
        await this.handleSetLocalStorage(responseJson);
        this.navigateToDashboard();
      } else {
        //Check Error Response
        this.ErrorHandler(responseJson);
      }
    } else if (apiRequestCallId === this.sendEmailRecoveryLinkAPICallID) {
      if (!responseJson.error) {
        this.setState({
          screenContentType: "checkYourEmail",
        });
        await StorageProvider.set("email", this.state.email);
      } else {
        //Check Error Response
        this.SentEmailErrorHandler(responseJson.error);
      }
    }
  };

  ErrorHandler = (responseJson: any) => {
    if (responseJson.errors.email_error) {
      this.setState({
        emailerrortext: responseJson.errors.email_error,
        passerrortext: "",
      });
    } else if (responseJson.errors.password_error) {
      this.setState({
        passerrortext: responseJson.errors.password_error,
        emailerrortext: "",
      });
    }
  };

  SentEmailErrorHandler = (error: string) => {
    this.setState({ emailerrortext: error });
  };

  handleSetLocalStorage = async (responseJson: any) => {
    await StorageProvider.set("token", responseJson.meta.token);
    await StorageProvider.set("name_intial", responseJson.account.data.attributes.first_name);
    await StorageProvider.set(
      "first_login",
      responseJson.account.data.attributes.first_login
    );
  };

  _check = (responseJson: any) => {
    this.arrayholder = responseJson.data;
    if (this.arrayholder && this.arrayholder.length !== 0) {
      let regexData = this.arrayholder[0];

      if (regexData.password_validation_regexp) {
        this.passwordReg = new RegExp(regexData.password_validation_regexp);
      }

      if (regexData.password_validation_rules) {
        this.setState({
          passwordHelperText: regexData.password_validation_rules,
        });
      }

      if (regexData.email_validation_regexp) {
        this.emailReg = new RegExp(regexData.email_validation_regexp);
      }
    }
  };

  navigationPayLoadReceive = (message: any) => {
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const otpAuthTkn = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );
      if (otpAuthTkn && otpAuthTkn.length > 0) {
        this.setState({ otpAuthToken: otpAuthTkn });
        runEngine.debugLog("otpAuthTkn", this.state.otpAuthToken);
        runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
      }
    }
  };

  countryCodeReceive = (message: any) => {
    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      let selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );

      if (selectedCode !== undefined) {
        this.setState({
          countryCodeSelected:
            selectedCode.indexOf("+") > 0
              ? selectedCode.split("+")[1]
              : selectedCode,
        });
      }
    }
  };

  loginAPICall = async () => {
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    this.setState({ emailerrortext: "", passerrortext: "" });
    if (this.state.email === '') {
      this.setState({ emailerrortext: "Email is required", passerrortext: "" });
      return
    }
    if (!this.state.email.match(emailRegex)) {
      this.setState({ emailerrortext: "Invalid email adddress", passerrortext: "" });
      return
    }
    if (this.state.password == "") {
      this.setState({
        passerrortext: "Password is required",
        emailerrortext: "",
      });
      return;
    }
    const header = {
      "Content-Type": "application/json",
    };
    const attrs = {
      email: this.state.email,
      password: this.state.password,
    };
    const data = {
      type: "email_account",
      attributes: attrs,
    };
    const httpBody = {
      data: data,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.loginAPICallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPI_EndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  sendEmailRecoveryLink = async () => {
    this.setState({ emailerrortext: "" });
    const regex = /^[a-zA-Z0-9.+_-]+@/;
    if (!regex.test(this.state.email)) {
      this.setState({ emailerrortext: "Enter a valid email" });
      return;
    }
    let formdata = new FormData();
    formdata.append("email", String(this.state.email));
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.sendEmailRecoveryLinkAPICallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sendRecoveryLink
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({})
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleBackToLoginClick = () => {
    this.setState({
      screenContentType: "login",
    });
  };

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible,
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible,
  };

  handleRememberMe = () => {
    this.setState(prevState => ({
      rememberMe: !prevState.rememberMe
    }), async() => {
      if (this.state.rememberMe) {
        await StorageProvider.set('email', this.state.email);
        await StorageProvider.set('password', this.state.password);
      } else {
        await StorageProvider.remove('email');
        await StorageProvider.remove('password');
      }
    })
  }

  // Customizable Area End
}
