import React from "react";
// Customizable Area Start
import NewPasswordController, {
  Props
} from "./NewPasswordController";
import { loginSideImage, bglogo } from "./assets";
import {
  Grid,
  Typography,
  TextField,
  Button,
  Box,
  InputAdornment,
  IconButton,
  Snackbar,
  SnackbarContent,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from '@material-ui/icons/Cancel';
const theme = createTheme({
  palette: {
    primary: {
      main: "#001E62",
      contrastText: "#001E62"
    }
  },
  typography: {
    h6: {
      fontWeight: 700
    },
    h5: {
      fontWeight: 700
    },
    body2: {
      fontWeight: 600,
      margin: "10px 0px"
    },
    fontFamily: "Graphik"
  }
});
// Customizable Area End
export default class NewPassword extends NewPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const {
      email,
      password,
      reTypePassword,
      enablePasswordField,
      enableReTypePasswordField,
      validlength,
      hasUppercase,
      hasLowercase,
      hasDigit,
      confirmPasswordError,
      screenContentType,
      passwordUpdateError
    } = this.state;
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={passwordUpdateError !== ""}
          autoHideDuration={3000}
          onClose={() => this.setState({passwordUpdateError:""})}
        >
          <SnackbarContent
            style={{
              backgroundColor: "#fff",
              padding: "0px 8px",
              borderRadius: 8,
            }}
            message={
              <Box style={{ display: "flex", alignItems: "center" }}>
                <CancelIcon style={{ color: 'red' }} />
                <Typography
                  variant="body1"
                  style={{ color: "#001E62", marginLeft: 5 }}
                >
                  {passwordUpdateError}
                </Typography>
              </Box>
            }
          />
        </Snackbar>
        <Grid
          container
          style={{
            height: "100vh"
          }}
        >
          <Grid
            item
            xs={12}
            md={6}
            style={{ padding: "15px 25px", height: "100%" }}
          >
            <Typography
              color="primary"
              variant="h5"
              style={{ fontStyle: "italic" }}
            >
              asics Indonesia
            </Typography>
            <Box
              style={webStyle.gridLeftBackground}
            >
              {screenContentType === 'newPassword' && <Box style={{ width: 350 }}>
                <Typography
                  variant="h5"
                  color="primary"
                  style={{ marginBottom: 30 }}
                >
                  New password
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="primary"
                  style={{ marginBottom: 30 }}
                >
                  {`Enter new password for ${email}`}
                </Typography>
                <Typography variant="body2" color="primary">
                  New Password
                </Typography>
                <TextField
                  data-test-id="password"
                  variant="outlined"
                  size="small"
                  color="primary"
                  placeholder="Password"
                  type={enablePasswordField ? "password" : "text"}
                  fullWidth
                  value={password}
                  onChange={(event) =>
                    this.handlePasswordChange(event.target.value)
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          style={{ marginRight: "-12px" }}
                          onClick={() =>
                            this.setState({
                              enablePasswordField: !enablePasswordField
                            })
                          }
                        >
                          {!enablePasswordField ? (
                            <VisibilityOutlinedIcon />
                          ) : (
                            <VisibilityOffOutlinedIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                <Typography variant="body2" color="primary">
                  Confirm New Password
                </Typography>
                <TextField
                  data-test-id="confirmPassword"
                  variant="outlined"
                  size="small"
                  color="primary"
                  placeholder="Password"
                  type={enableReTypePasswordField ? "password" : "text"}
                  fullWidth
                  value={reTypePassword}
                  onChange={(e: any) =>
                    this.handleConfirmPasswordChange(e.target.value)
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          style={{ marginRight: "-12px" }}
                          onClick={() =>
                            this.setState({
                              enableReTypePasswordField: !enableReTypePasswordField
                            })
                          }
                        >
                          {!enableReTypePasswordField ? (
                            <VisibilityOutlinedIcon />
                          ) : (
                            <VisibilityOffOutlinedIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  error={confirmPasswordError}
                  helperText={confirmPasswordError && "Passwords do not match"}
                />
                <Box style={{ marginTop: 25 }}>
                  <Typography style={{ fontSize: "1rem" }} color="primary">
                    Your password must contain
                  </Typography>
                  <Box style={webStyle.passwordCheckContainer}>
                    <CheckCircleIcon
                      style={{
                        color: hasUppercase ? "#059669" : "#757575",
                        ...webStyle.checkIcon,
                      }}
                    />
                    <Typography variant="subtitle1" color="primary">
                      At least one capital letter
                    </Typography>
                  </Box>
                  <Box style={webStyle.passwordCheckContainer}>
                    <CheckCircleIcon
                      style={{
                        color: hasLowercase ? "#059669" : "#757575",
                        ...webStyle.checkIcon,
                      }}
                    />
                    <Typography variant="subtitle1" color="primary">
                      At least one lowercase letter
                    </Typography>
                  </Box>
                  <Box style={webStyle.passwordCheckContainer}>
                    <CheckCircleIcon
                      style={{
                        color: hasDigit ? "#059669" : "#757575",
                        ...webStyle.checkIcon,
                      }}
                    />
                    <Typography variant="subtitle1" color="primary">
                      At least one number
                    </Typography>
                  </Box>
                  <Box style={webStyle.passwordCheckContainer}>
                    <CheckCircleIcon
                      style={{
                        color: validlength ? "#059669" : "#757575",
                        ...webStyle.checkIcon,
                      }}
                    />
                    <Typography variant="subtitle1" color="primary">
                      Minimum character length is 8 characters
                    </Typography>
                  </Box>
                </Box>
                <Button
                  style={{
                    width: "100%",
                    height: 50,
                    borderRadius: 10,
                    backgroundColor: "#00C3E6",
                    color: "#fff",
                    textTransform:'none',
                    marginTop:10,
                    opacity:this.checkDisabled() ? '40%' : '100%'
                  }}
                  disabled={this.checkDisabled()}
                  onClick={this.passwordReset}
                  data-test-id="newPasswordButton"
                >
                  Set New Password
                </Button>
              </Box>}
              {screenContentType === 'passwordChanged' && <Box style={{ width: 350 }}>
                <Typography
                  variant="h5"
                  color="primary"
                  style={{ marginBottom: 10 }}
                >
                  Password successfully changed
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="primary"
                  style={{ marginBottom: 30 }}
                >
                  Your password is successfully changed
                </Typography>
                <Typography
                  variant="body2"
                  style={{ marginTop:55 }}
                >
                  Back to 
                  <span style={{ color: "#00C3E6", cursor: "pointer", marginLeft:5 }}
                    onClick={this.handleBackToLoginClick}
                    data-test-id='backToLogin'
                  >
                    Log in
                  </span>
                </Typography>
              </Box>}
            </Box>
          </Grid>
          <Grid
            item
            xs={false}
            md={6}
            style={{
              backgroundImage: `url(${loginSideImage})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
              borderBottomLeftRadius: "40px",
            }}
          />
        </Grid>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyle = {
  passwordCheckContainer: {
    display: "flex",
    alignItems: "center",
  },
  checkIcon: {
    marginRight: 5,
    height: 15,
    width: 15,
  },
  gridLeftBackground:{
    width: "100%",
    height: "calc(100% - 47px)",
    backgroundImage: `url(${bglogo})`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "center"
  }
};
// Customizable Area End
