Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/dashboard/dashboards";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodTypeGet = "GET";
exports.dashboarApiMethodTypeGetPatch = 'PATCH';
exports.dashboarApiMethodTypeGetPut = 'PUT';
exports.dashboarApiMethodTypeGetDelete = 'DELETE';
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.labelBodyText = "dashboard Body";
exports.updatePassword="account_block/accounts/update_password"
exports.changePassword="account_block/accounts/passwords/update_password"
exports.getUserDetails="account_block/accounts/get_user"
exports.updateUserDetails="account_block/accounts/update"
exports.updateUserProfile="account_block/accounts/set_avatar"
exports.removeUserProfile="account_block/accounts/remove_avatar"
exports.uploadHistory="bx_block_documentopener2/documents/upload_history"
exports.uploadedPDFParseData = "bx_block_cffetchdetailsfrominvoicetemplate/parse_invoices";
exports.dashboardDataAPIEndPoint = "bx_block_dashboard/dashboards"
exports.exportDataAPIEndPoint = "bx_block_dashboard/dashboards/export_csv"
exports.tableHeader = [
  "Inspection Fee",
  "Custom Duty",
  "Service Fee/Dutyfree",
  "Freight",
  "Others(INSURANCE/DOC/MCQ)",
]
exports.postTypeApiRequest = "POST"
exports.resyncDocumentApiCallEndpoint ="bx_block_documentopener2/documents/sync_with_netsuite"
// Customizable Area End