import React from "react";

// Customizable Area Start
import { createTheme, ThemeProvider, withStyles, Theme } from "@material-ui/core/styles";
import {
  Grid,
  Box,
  Typography,
  TextField,
  InputAdornment,
  Tabs,
  Tab,
  SvgIcon,
  Paper,
  Chip,
  Tooltip,
  Avatar,
  Snackbar,
  SnackbarContent,
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import SearchIcon from "@material-ui/icons/Search";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { UploadHistoryType } from "./UploadHistoryController.web";
import moment from "moment";
import { questionmark, syncIcon } from './assets'
const uploadHistorytheme = createTheme({
  palette: {
    primary: {
      main: "#001E62",
      contrastText: "#001E62"
    }
  },
  typography: {
    h5: {
      fontWeight: 700,
      lineHeight: 1
    },
    h6: {
      fontWeight: 700,
      lineHeight: 1
    },
    body2: {
      fontWeight: 600,
      marginLeft: 5
    },
    subtitle1: {
      fontSize: "0.8rem"
    },
    fontFamily: "Graphik"
  },
  overrides: {
    MuiButton: {
      root: {
        "&:hover": {
          backgroundColor: "transparent",
          boxShadow: "none"
        }
      }
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 8,
        backgroundColor: "#FFF"
      }
    },
    MuiTab: {
      root: {
        textTransform: "none"
      }
    },
    MuiChip: {
      root: {
        borderRadius: 8,
        backgroundColor: "#E8EFFF",
        color: "#001E63",
        height: 40,
        fontSize: "1rem"
      }
    }
  }
});

// Customizable Area End

import UploadHistoryController from "./UploadHistoryController.web";
export default class UploadHistory extends UploadHistoryController {
  // Customizable Area Start
  // Customizable Area End

  render() {
    const { uploadHistoryTabType } = this.state;
    return (
      // Customizable Area Start
      <ThemeProvider theme={uploadHistorytheme}>
        <Snackbar
          autoHideDuration={3000}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={this.state.isToastOpen}
          onClose={this.handleCloseAlert}
          data-testID="snackbarAlert"
        >
          <SnackbarContent 
            message={<Box style={{ display: "flex", alignItems: "center" }}>
                <CheckCircleIcon style={{ color: "#059669" }} />
                <Typography
                  variant="body1"
                  style={{ color: "#001E62", marginLeft: 5 }}
                >
                  {this.state.ToatstMsg}
                </Typography>
              </Box>
            }
            style={{
              padding: "0px 8px",
              borderRadius: 8,
              backgroundColor: "#fff",
            }}
          />
        </Snackbar>
        <Grid container justifyContent="space-between" spacing={2}>
          <Grid item xs={12} md={7}>
            <Typography color="primary" variant="h5">
              Upload History
            </Typography>
          </Grid>
          <Grid item xs={12} md={5}>
            <TextField
              data-test-id="searchQuery"
              variant="outlined"
              size="small"
              color="primary"
              placeholder="Search"
              type="text"
              fullWidth
              onChange={event => this.handleSearchQueryChange(event.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
        </Grid>
        <Tabs
          value={uploadHistoryTabType}
          onChange={this.handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          aria-label="disabled tabs example"
          TabIndicatorProps={{
            style: { backgroundColor: "transparent", textTransform: "none" }
          }}
          TabScrollButtonProps={{ style: { textTransform: "none" } }}
        >
          {["Approved", "Submitted", "Rejected"].map((status: string) => (
            <Tab key={status} label={status} data-test-id={`tab${status}`} />
          ))}
        </Tabs>
        {this.state.filteredUploadHistoryData.map(
          (uploadHistory: UploadHistoryType) => (
            <FileCard uploadHistory={uploadHistory} data-testID="fileCard" resyncDocument={(value:string) => this.resyncDocument(value)} />
          )
        )}
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const SubmittedIcon = (props: any) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="10" cy="10" r="5" fill="#001E63" />
        <rect
          x="0.5"
          y="0.5"
          width="19"
          height="19"
          rx="5.5"
          stroke="#001E63"
        />
      </svg>
    </SvgIcon>
  );
};

export const ApprovedIcon = (props: any) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="20" height="20" rx="6" fill="#059669" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.061 5.42238C15.5182 5.73229 15.6375 6.35411 15.3276 6.81124L10.243 14.3112C10.077 14.5562 9.81098 14.715 9.51659 14.745C9.2222 14.7749 8.92965 14.673 8.71765 14.4665L4.80222 10.6536C4.40654 10.2683 4.39814 9.6352 4.78345 9.23953C5.16876 8.84386 5.80187 8.83546 6.19754 9.22077L9.25759 12.2007L13.6722 5.68895C13.9821 5.23182 14.6039 5.11247 15.061 5.42238Z"
          fill="white"
        />
      </svg>
    </SvgIcon>
  );
};

export const RejectedIcon = (props: any) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5 5L15 15M5 15L15 5"
          stroke="#D91F06"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <rect
          x="0.5"
          y="0.5"
          width="19"
          height="19"
          rx="5.5"
          stroke="#D91F06"
        />
      </svg>
    </SvgIcon>
  );
};

export const FileCard: React.FC<{
  uploadHistory: UploadHistoryType;
  resyncDocument: (val: string) => void;
}> = props => {
  const { uploadHistory } = props;
  const {
    status,
    name,
    size,
    updated_at,
    bill_type_name,
    netsuite_sync
  } = uploadHistory.attributes;
  let backgroundColor = "";
  let color = "";
  switch (status) {
    case "approved":
      backgroundColor = netsuite_sync === "Failed" ? "#FAD8D1" : "#D1FAE5";
      color = netsuite_sync === "Failed" ? "#D91F06": "#059669";
      break;
    case "submitted":
      backgroundColor = "#FAE7D1";
      color = "#D97706";
      break;
    case "rejected":
      backgroundColor = "#FAD8D1";
      color = "#D91F06";
      break;
    default:
      break;
  }
  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      component={Paper}
      elevation={0}
      style={netsuite_sync === "Failed" ? webStyle.resyncFileCard : webStyle.fileCard}
    >
      <Grid
        xs={12}
        sm={4}
        md={3}
        lg={3}
        item
        spacing={1}
        component={Box}
        display="flex"
      >
        {status === "approved" && <ApprovedIcon />}
        {status === "submitted" && <SubmittedIcon />}
        {status === "rejected" && <RejectedIcon />}
        <Box>
          <Typography variant="h6">{name}</Typography>
          <Typography variant="subtitle1">{size}</Typography>
        </Box>
      </Grid>
      <Grid item xs={10} sm={4} md={2} lg={5}>
        <Chip style={{ backgroundColor: netsuite_sync === "Failed" ? "white" : ""}} label={bill_type_name} />
      </Grid>
      <Grid xs={10} sm={2} md={2} lg={2} item>
        {netsuite_sync === "Failed" && <Box style={{ display: "flex", gap: "15px" }}>
          <Chip
            label={"Resync"}
            style={{ backgroundColor: "white"}}
            data-testID={`doc-${uploadHistory.id}`}
            onClick={() => props.resyncDocument(uploadHistory.id)}
            avatar={
              <img src={syncIcon} alt="syncIcon" />
            }
          />

          <Box>
            <Avatar variant="square" style={{ borderRadius: "8px", backgroundColor: "white", cursor: "pointer" }}>
              <CustomTooltip
                arrow
                title={
                  <React.Fragment>
                    <Typography color="inherit">
                      The data sync to Oracle NetSuite was not successful, please click
                      "resync" to try again.
                    </Typography>
                  </React.Fragment>
                }
                placement="top-end"
              >
                <img src={questionmark} alt="que" />
              </CustomTooltip>
            </Avatar>
          </Box>
        </Box>}  
      </Grid>
      <Grid xs={10} sm={2} md={2} lg={2} item>
        <Box
          style={ netsuite_sync === "Failed" ? {
            ...webStyle.resyncDateChip,
            backgroundColor,
            color,
          } :{
            ...webStyle.dateChip,
            backgroundColor,
            color,
          }}
        >
          <AccessTimeIcon />
          <Typography variant="body2">
            {moment(updated_at).format("MMM D, YYYY")}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

const CustomTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: "#001E63",
    color: "white",
    maxWidth: 220,
    fontSize: "14px",
    border: "1px solid #dadde9",
    borderRadius: "10px"
  },
  arrow: {
    color:"#001E63",
  },
}))(Tooltip);

const webStyle = {
  fileCard: {
    backgroundColor: "#fff",
    borderRadius: "8px 8px 32px 8px",
    marginTop: 20,
    padding: 10
  },
  resyncFileCard : {
    backgroundColor: "#F6DFDF",
    borderRadius: "8px 8px 32px 8px",
    marginTop: 20,
    padding: 10
  },
  
  dateChip: {
    borderRadius: 80,
    padding: "2px 8px",
    display: "inline-flex",
    alignItems: "center"
  },
  resyncDateChip: {
    borderRadius: 80,
    border: "1px solid red",
    padding: "2px 8px",
    display: "inline-flex",
    alignItems: "center"
  }
};
// Customizable Area End
