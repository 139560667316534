import React from "react";
// Customizable Area Start
import EmailAccountRegistrationController, {
  Props
} from "./EmailAccountRegistrationController";
import { loginSideImage, bglogo } from "./assets";
import {
  Grid,
  Typography,
  TextField,
  Button,
  Checkbox,
  Box,
  InputAdornment,
  IconButton
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
const theme = createTheme({
  palette: {
    primary: {
      main: "#001E62",
      contrastText: "#001E62"
    }
  },
  typography: {
    h6: {
      fontWeight: 700
    },
    h5: {
      fontWeight: 700
    },
    body2: {
      fontWeight: 600,
      margin: "10px 0px"
    },
    fontFamily: "Graphik"
  }
});
import {webStyle} from "./NewPassword.web"
// Customizable Area End
export default class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const {
      email,
      password,
      enablePasswordField,
      emailerrortext,
      passerrortext,
      screenContentType,
    } = this.state;
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Grid
          container
          style={{
            height: "100vh"
          }}
        >
          <Grid
            item
            xs={12}
            md={6}
            style={{ padding: "15px 25px", height: "100%" }}
          >
            <Typography
              color="primary"
              variant="h5"
              style={{ fontStyle: "italic" }}
            >
              ASICS Indonesia
            </Typography>
            <Box
              style={webStyle.gridLeftBackground}
            >
              {/* Login */}
              {screenContentType === 'login' && <Box style={{ width: 350 }}>
                <Typography
                  variant="h5"
                  color="primary"
                  style={{ marginBottom: 30 }}
                >
                  Sign in to AP Automation
                </Typography>
                <Typography variant="body2" color="primary">
                  Email
                </Typography>
                <TextField
                  data-test-id="email"
                  variant="outlined"
                  size="small"
                  color="primary"
                  placeholder="Email"
                  fullWidth
                  value={email}
                  onChange={(e: any) =>
                    this.setState({ email: e.target.value })
                  }
                  error={emailerrortext}
                  helperText={emailerrortext}
                />
                <Typography variant="body2" color="primary">
                  Password
                </Typography>
                <TextField
                  data-test-id="password"
                  variant="outlined"
                  size="small"
                  color="primary"
                  placeholder="Password"
                  type={enablePasswordField ? "password" : "text"}
                  fullWidth
                  value={password}
                  onChange={(e: any) =>
                    this.setState({ password: e.target.value })
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          style={{ marginRight: "-12px" }}
                          onClick={() =>
                            this.setState({
                              enablePasswordField: !enablePasswordField
                            })
                          }
                        >
                          {!enablePasswordField ? (
                            <VisibilityOutlinedIcon />
                          ) : (
                            <VisibilityOffOutlinedIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  error={passerrortext}
                  helperText={passerrortext}
                />
                <Box
                  style={{
                    margin: "15px 0px",
                    display: "flex",
                    justifyContent: "space-between"
                  }}
                >
                  <Box style={{ display: "flex", alignItems: "center" }}>
                    <Checkbox color="primary" style={{ marginLeft: "-10px" }} checked={this.state.rememberMe} onChange={this.handleRememberMe} data-testid="check-box"/>
                    <Typography variant="subtitle1">Remember me</Typography>
                  </Box>
                  <Typography
                    variant="body2"
                    style={{ color: "#00C3E6", cursor: "pointer" }}
                    data-test-id='forgotPassword'
                    onClick={() => this.setState({
                      screenContentType:'forgotPassword'
                    })}
                  >
                    Forgot password
                  </Typography>
                </Box>
                <Button
                  style={{
                    width: "100%",
                    height: 50,
                    borderRadius: 10,
                    backgroundColor: "#00C3E6",
                    color: "#fff",
                    textTransform:'none'
                  }}
                  onClick={this.loginAPICall}
                  data-test-id="logginButton"
                >
                  Log in
                </Button>
              </Box>}
              {screenContentType === 'forgotPassword' && <Box style={{ width: 350 }}>
                <Typography
                  variant="h5"
                  color="primary"
                  style={{ marginBottom: 30 }}
                >
                  Forgot password
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="primary"
                  style={{ marginBottom: 30 }}
                >
                  No worries, We'll send a recovery link to your email.
                </Typography>
                <Typography variant="body2" color="primary">
                  Email
                </Typography>
                <TextField
                  data-test-id="emailForPasswordRecovery"
                  variant="outlined"
                  size="small"
                  color="primary"
                  placeholder="Email"
                  fullWidth
                  value={email}
                  onChange={(e: any) =>
                    this.setState({ email: e.target.value })
                  }
                  error={emailerrortext}
                  helperText={emailerrortext}
                />
                <Button
                  style={{
                    width: "100%",
                    height: 50,
                    borderRadius: 10,
                    backgroundColor: "#00C3E6",
                    color: "#fff",
                    marginTop:15,
                    textTransform:'none'
                  }}
                  onClick={this.sendEmailRecoveryLink}
                  data-test-id="sendRecoveryLink"
                >
                  Send a recovery link
                </Button>
              </Box>}
              {screenContentType === 'checkYourEmail' && <Box style={{ width: 350 }}>
                <Typography
                  variant="h5"
                  color="primary"
                  style={{ marginBottom: 10 }}
                >
                  Check your email
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="primary"
                  style={{ marginBottom: 30 }}
                >
                  {`We have sent a password reset link to 
                  ${email}`}
                </Typography>
                <Typography
                  variant="body2"
                  style={{ color: "#00C3E6", cursor: "pointer", marginTop:15 }}
                >
                  Resend e-mail
                </Typography>
                <Typography
                  variant="body2"
                  style={{ marginTop:55 }}
                >
                  Back to 
                  <span style={{ color: "#00C3E6", cursor: "pointer", marginLeft:5 }}
                    onClick={this.handleBackToLoginClick}
                  >
                    Log in
                  </span>
                </Typography>
              </Box>}
            </Box>
          </Grid>
          <Grid
            item
            xs={false}
            md={6}
            style={{
              backgroundImage: `url(${loginSideImage})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
              borderBottomLeftRadius: "40px",
            }}
          />
        </Grid>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
