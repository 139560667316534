import React from "react";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
  Grid,
  Box,
  Typography,
  Avatar,
  Popover,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import UpdateIcon from "@material-ui/icons/Update";
import BackupOutlinedIcon from "@material-ui/icons/BackupOutlined";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import ExitToAppRoundedIcon from "@material-ui/icons/ExitToAppRounded";
import DashboardController from "./DashboardController.web";
import ResetPasswordDialog from "./ResetPasswordDialog.web";
import ProfileSetting from "./ProfileSetting.web";
import DocumentOpener from "../../documentopener/src/DocumentOpener.web";
import DashboardTable from "./DashboardTable.web";
import DocumentPreview from "../../cffetchdetailsfrominvoicetemplate/src/DocumentPreview.web";
const logo = require("../assets/logo.svg");
import { bglogo } from "./assets"
import UploadHistory from "./UploadHistory.web";
const theme = createTheme({
  palette: {
    primary: {
      main: "#001E62",
      contrastText: "#001E62",
    },
  },
  typography: {
    h5: {
      fontWeight: 700,
    },
    body2: {
      fontWeight: 600,
      margin: "10px 0px",
      marginTop: 25,
    },
    subtitle1: {
      fontSize: "0.8rem",
      fontWeight: 400,
    },
    fontFamily: "Graphik",
  },
  overrides: {
    MuiList: {
      root: {
        margin: 0,
        padding: "5px",
      },
    },
    MuiListItem: {
      root: {
        "&:hover": {
          backgroundColor: "#e8efff",
        },
        borderRadius: "8px",
        cursor: "pointer",
      },
    },
    MuiPopover: {
      paper: {
        borderRadius: "8px",
      },
    },
  },
});
// Customizable Area End

export default class Dashboard extends DashboardController {
  // Customizable Area Start
  // Customizable Area End

  render() {
    const {
      contentType,
      popoverAnchor,
      isChangePasswordDialogOpen,
      updatePasswordType,
    } = this.state;
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <ResetPasswordDialog
          navigation={this.props.navigation}
          id={this.props.id}
          isChangePasswordDialogOpen={isChangePasswordDialogOpen}
          updatePasswordType={updatePasswordType}
          handleChangePasswordDialogClose={this.handleChangePasswordDialogClose}
        />
        <Grid container style={webStyle.dashboardContainer}>
          <Grid
            item
            md={1}
            sm={2}
            xs={3}
            style={{ ...webStyle.sideBar, flexDirection: "column" }}
          >
            <img src={logo} alt="logo" />
            <Box>
              <Box
                data-test-id='uploadDocument'
                style={{
                  ...webStyle.menuType,
                  flexDirection: "column",
                  color:
                    contentType === "uploadDocument" ? "#001E62" : "#64748B",
                }}
                onClick={() => this.handleContentTypeSwitch("uploadDocument")}
              >
                <BackupOutlinedIcon />
                <Typography variant="subtitle2" style={{ textAlign: "center" }}>
                  Upload Documents
                </Typography>
              </Box>
              <Box
                data-test-id='uploadHistory'
                style={{
                  ...webStyle.menuType,
                  flexDirection: "column",
                  color:
                    contentType === "uploadHistory" ? "#001E62" : "#64748B",
                }}
                onClick={() => this.handleContentTypeSwitch("uploadHistory")}
              >
                <UpdateIcon />
                <Typography variant="subtitle2" style={{ textAlign: "center" }}>
                  Upload History
                </Typography>
              </Box>
              <Box
                data-test-id='dashboard'
                style={{
                  ...webStyle.menuType,
                  flexDirection: "column",
                  color: contentType === "dashboard" ? "#001E62" : "#64748B",
                }}
                onClick={() => this.handleContentTypeSwitch("dashboard")}
              >
                <HomeOutlinedIcon />
                <Typography variant="subtitle2" style={{ textAlign: "center" }}>
                  Dashboard
                </Typography>
              </Box>
            </Box>
            <Avatar
              data-test-id="profileAvatar"
              style={{ backgroundColor: "#00C3E6", color: "#001E63", cursor: 'pointer' }}
              onClick={(e) => this.setState({ popoverAnchor: e.currentTarget })}
            >
              {this.state.nameInitial.charAt(0).toUpperCase()}
            </Avatar>
            <Popover
              id="popover"
              open={Boolean(popoverAnchor)}
              anchorEl={popoverAnchor}
              onClose={() => this.setState({ popoverAnchor: null })}
              style={{
                borderRadius: 8,
              }}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
            >
              <List component="nav" aria-label="main mailbox folders">
                <ListItem
                  data-test-id="myAccount"
                  onClick={this.handleMyAccountClick}
                >
                  <ListItemIcon>
                    <PersonOutlineIcon />
                  </ListItemIcon>
                  <ListItemText primary="My Account" />
                </ListItem>
                <ListItem
                  data-test-id="logout"
                  onClick={this.handleLogoutClick}
                >
                  <ListItemIcon>
                    <ExitToAppRoundedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Logout" />
                </ListItem>
              </List>
            </Popover>
          </Grid>
          <Grid item md={11} sm={10} xs={9} style={{ padding: 20, height: '100%', overflowY: 'auto' }}>
            {contentType === "profileSetting" && (
              <ProfileSetting
                navigation={this.props.navigation}
                id={this.props.id}
                handlePasswordChangeClick={this.handlePasswordChangeClick}
              />
            )}
            {contentType === "uploadDocument" && (
              <DocumentOpener
                navigation={this.props.navigation}
                id={this.props.id}
                handleContentTypeSwitch={this.handleContentTypeSwitch}
                handleSetSelectedDocId={this.handleSetSelectedDocId}
              />
            )}
            {contentType === "documentPreview" && (
              <DocumentPreview
                navigation={this.props.navigation}
                id={this.props.id}
                handleContentTypeSwitch={this.handleContentTypeSwitch}
                selectedDocumentId={this.state.selectedDocumentId}
              />
            )}
            {contentType === "dashboard" && (
              <DashboardTable
                navigation={this.props.navigation}
                id={this.props.id}
              />
            )}
            {contentType === "uploadHistory" && (
              <UploadHistory
                navigation={this.props.navigation}
                id={this.props.id}
              />
            )}
          </Grid>
        </Grid>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  dashboardContainer: {
    height: "100vh",
    backgroundColor: "#f1f5f9",
    backgroundImage: `url(${bglogo})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center"
  },
  sideBar: {
    height: "100%",
    backgroundColor: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 15,
  },
  menuType: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 20,
    cursor:'pointer'
  },
};
// Customizable Area End
