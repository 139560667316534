import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {SnackbarLoaderContext} from "../../../components/src/context"
import moment from "moment";
import StorageProvider from "framework/src/StorageProvider.web";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  popoverAnchor: any;
  dataSelectionArray: string[];
  tempDataSelectionArray: string[];
  dateRange:Date[] | null[],
  dashboardTableData:any,
  searchQuery:string,
  page:number,
  totalPages:number
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class DashboardTableController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDashboardDataAPICallId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      popoverAnchor: null,
      dataSelectionArray: [],
      tempDataSelectionArray: [],
      dateRange: [new Date(), new Date()],
      dashboardTableData:'',
      searchQuery:'',
      page:1,
      totalPages:0
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getDashboardTableDataAPICall()
    // Customizable Area End
  }
  // Customizable Area Start

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.receiveDashboardTable(message)
    // Customizable Area End
  }
  static contextType = SnackbarLoaderContext

  receiveDashboardTable = (message: Message) => {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        this.apiResponseHandller(apiRequestCallId, responseJson);
      }
    }
  };

  apiResponseHandller = (apiRequestCallId: any, responseJson: any) => {
    this.context.unsetLoader && this.context.unsetLoader()
    if (apiRequestCallId === this.getDashboardDataAPICallId) {
      if(responseJson.data.attributes){
        this.setState({
          dashboardTableData:responseJson.data.attributes.rows,
          totalPages:responseJson.data.attributes.total_pages,
        })
      }else{
        this.setState({
          dashboardTableData:'',
          totalPages:0,
        })
      }
    }
  };

  filterTableHeader = () => {
    const {dataSelectionArray} = this.state
    if(dataSelectionArray.length>0){
      return configJSON.tableHeader.filter((item:string) => dataSelectionArray.includes(item))
    } else {
      return configJSON.tableHeader
    }
  }

  debounce = (cb: any, delay: number = 1000) => {
    let timeout: string | number | NodeJS.Timeout | undefined;
    return (...args: any) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        cb(...args);
      }, delay);
    };
  };

  updateSearchText = this.debounce((text: string) => {
    this.setState({
      searchQuery: text,
    }, () => this.getDashboardTableDataAPICall());
  }, 1000);

  handleDataSelection = (value: string) => {
    const { tempDataSelectionArray } = this.state;
    const currentIndex = tempDataSelectionArray.indexOf(value);
    const newChecked = [...tempDataSelectionArray];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    this.setState({
      tempDataSelectionArray: newChecked,
    });
  };

  handleResetDataSelection = () => {
    this.setState({ 
      dataSelectionArray: [], 
      tempDataSelectionArray: [],
      popoverAnchor:null 
    }, () => this.getDashboardTableDataAPICall());
  };

  handleDateRangeChange = (dates:any) => {
    this.setState({
      dateRange:dates
    }, () => {
      if(dates[0] !== null && dates[1] !== null){
        this.getDashboardTableDataAPICall()
      }
    })
    
  }

  handleDataSelectionSaveClick = () => {
    this.setState({
      popoverAnchor:null
    }, () => this.getDashboardTableDataAPICall())
  }

  handlePaginationChange = (e:React.ChangeEvent<unknown>, updatedPage:number) => {
    this.setState({
      page:updatedPage,
    }, () => this.getDashboardTableDataAPICall())
  }

  generateGetDashboardURL = (baseURL:string) => {
    const {searchQuery, dateRange, page, tempDataSelectionArray} = this.state
    let url:string = baseURL
    if(searchQuery){
      url += `?search_keyword=${searchQuery}`
    }
    if(tempDataSelectionArray && tempDataSelectionArray.length>0){
      url += `${url.includes('?') ? '&' : '?'}data_selection=${JSON.stringify(tempDataSelectionArray)}`
    }
    const today = moment(new Date()).format('DD/MM/YYYY')
    const startDate = moment(dateRange[0]).format('DD/MM/YYYY')
    const endDate = moment(dateRange[1]).format('DD/MM/YYYY')
    if(!(today === startDate && today === endDate)){
      url += `${url.includes('?') ? '&' : '?'}start_date=${startDate}&end_date=${endDate}`
    }
    url += `${url.includes('?') ? '&' : '?'}page=${page}`
    this.setState({
      dataSelectionArray:tempDataSelectionArray
    })
    return url
  }

  getDashboardTableDataAPICall = async () => {
    this.handleSetLoader()
    const dynamicUrl = this.generateGetDashboardURL(configJSON.dashboardDataAPIEndPoint)
    const header = {
      token: await StorageProvider.get("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDashboardDataAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${dynamicUrl}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleExportButtonClick = () => {
    const url = this.generateGetDashboardURL(configJSON.exportDataAPIEndPoint)
    const msg: Message = new Message(getName(MessageEnum.NavigationImportExportDataMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
    msg.addData(getName(MessageEnum.ImportExportDataInfo), url);
    this.send(msg);
  }

  handleSetLoader = () => {
    this.context.setLoader &&  this.context.setLoader()
  }
  // Customizable Area End
}
