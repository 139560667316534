import React from "react";
// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  IconButton,
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DocumentPreviewController from "./DocumentPreviewController";
import PDFPreview from "../../documentopener/src/PDFPreview.web";
import DynamicForm from "./DynamicForm.web";
import CancelIcon from "@material-ui/icons/Cancel";
import CloseIcon from "@material-ui/icons/Close";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";

const theme = createTheme({
  palette: {
    primary: {
      main: "#001E62",
      contrastText: "#001E62",
    },
  },
  typography: {
    h5: {
      fontWeight: 700,
    },
    body2: {
      fontWeight: 600,
      margin: "10px 0px",
      marginTop: 25,
    },
    body1: {
      fontWeight: 600,
    },
    subtitle1: {
      fontSize: "0.8rem",
    },
    fontFamily: "Graphik",
    button: {
      textTransform: "none",
    },
  },
});
function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
// Customizable Area End

export default class DocumentPreview extends DocumentPreviewController {
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Dialog
          maxWidth="md"
          open={this.state.isDialogOpen}
          PaperProps={{
            style: { borderBottomRightRadius: "32px", width: 460 },
          }}
        >
          <IconButton
            edge="end"
            color="inherit"
            onClick={() => this.handleDialogClose()}
            aria-label="close"
            style={webStyle.closeIcon}
            data-test-id="closeIconButton"
          >
            <CloseIcon />
          </IconButton>
          <DialogTitle>
            <Box style={webStyle.dialogTitleContainer}>
              <CancelIcon style={webStyle.cancelIcon} />
              <Typography variant="body1">{this.state.alertMessage}</Typography>
            </Box>
          </DialogTitle>
          <DialogActions style={webStyle.dialogAction}>
            <Button
              style={{ ...webStyle.dialogActionButton, textTransform: "none" }}
              data-test-id="okayConfirm"
              onClick={() => this.handleDialogClose()}
            >
              Okay
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          open={this.state.StatusToastopen}
          autoHideDuration={4000}
          onClose={this.handleStatusToastClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert
            onClose={this.handleStatusToastClose}
            severity={this.state.StatusToastSeverity}
          >
            {this.state.StatusToastMessage}
          </Alert>
        </Snackbar>
        <Box
          style={{
            padding: "10px 15px",
            height: "100%",
            backgroundColor: "transparent",
          }}
        >
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box style={{ display: "flex", alignItems: "center" }}>
              <IconButton
                data-test-id="backButton"
                aria-label="delete"
                style={{ marginRight: 5 }}
                onClick={this.handleBackClick}
              >
                <ArrowBackIcon />
              </IconButton>
              <Typography color="primary" variant="h5">
                Preview
              </Typography>
            </Box>
            <Box>
              <Button
                variant="outlined"
                style={{ ...webStyle.recalcButton, textTransform: "none" }}
                size="small"
                onClick={() => this.handleAddMemo()}
                data-test-id="formAddMemoButton"
              >
                Add Memo
              </Button>
              <Button
                variant="outlined"
                style={{ ...webStyle.recalcButton, textTransform: "none" }}
                size="small"
                onClick={() => this.handleSubmitClick()}
                data-test-id="formRecalcButton"
                disabled={this.state.isSubmitted}
              >
                Recalc
              </Button>
              <Button
                variant="contained"
                style={{ ...webStyle.submitButton, textTransform: "none" }}
                size="small"
                onClick={() => this.handleSubmitClick(true)}
                data-test-id="formSubmitButton"
                disabled={this.state.isSubmitted}
              >
                Submit
              </Button>
            </Box>
          </Box>
          {this.state.formData !== null && (
            <Grid container spacing={2} style={{ marginTop: 10 }}>
              <Grid item xs={12} md={6}>
                <PDFPreview
                  navigation={this.props.navigation}
                  id={this.props.id}
                  pdfURL={this.state.formData.pdf_urls}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DynamicForm
                  navigation={this.props.navigation}
                  id={this.props.id}
                  pdfParseData={this.state.formData}
                  handleFieldChange={this.handleFieldChange}
                  handleCancelChange={this.handleCancelMemo}
                  handleSubmitNewMemoData={this.handleSubmitNewMemoData}
                  removeMemoItemsById={this.removeMemoItemsById}
                  emptyFieldList={this.state.emptyFieldList}
                  memoItemsEmptyFieldList={this.state.memoItemsEmptyFieldList}
                  memoItemsFormContent={this.state.memoItemsFormContent}
                  locationOption={this.state.locationOption}
                  expenseOption={this.state.expenseOption}
                />
              </Grid>
            </Grid>
          )}
        </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyle = {
  recalcButton: {
    height: 40,
    borderRadius: 8,
    color: "#00C3E6",
    padding: "10px 20px",
    marginRight: 10,
  },
  submitButton: {
    height: 40,
    borderRadius: 8,
    backgroundColor: "#00C3E6",
    color: "#fff",
    padding: "10px 20px",
  },
  closeIcon: {
    margin: 0,
    marginLeft: "auto",
  },
  dialogTitleContainer: {
    color: "#001E62",
    display: "flex",
    alignItems: "center",
  },
  cancelIcon: {
    color: "#dc2626",
    marginRight: 20,
    backgroundColor: "#fee2e2",
    borderRadius: "50%",
    padding: 10,
  },
  dialogAction: {
    borderTop: "1px solid #757575",
  },
  dialogActionButton: {
    margin: 15,
    height: 50,
    borderRadius: 10,
    backgroundColor: "#00C3E6",
    color: "#fff",
  },
};
// Customizable Area End
