import React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Box,
  Snackbar,
  SnackbarContent,
  IconButton
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ResetPasswordController from "./ResetPasswordController";
import CloseIcon from "@material-ui/icons/Close";
export default class ResetPasswordDialog extends ResetPasswordController {
  constructor(props: any) {
    super(props);
  }
  render() {
    const {
      validlength,
      hasUppercase,
      hasLowercase,
      hasDigit,
      oldPassword,
      oldPasswordError,
      password,
      confirmedPassword,
      confirmPasswordError,
      isSuccessAlertOpen,
    } = this.state;
    const {
      isChangePasswordDialogOpen,
      updatePasswordType,
      handleChangePasswordDialogClose,
    } = this.props;
    return (
      <>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={isSuccessAlertOpen}
          autoHideDuration={3000}
          onClose={() => this.handleCloseSuccessAlert()}
        >
          <SnackbarContent
            style={{
              backgroundColor: "#fff",
              padding: "0px 8px",
              borderRadius: 8,
            }}
            message={
              <Box style={{ display: "flex", alignItems: "center" }}>
                <CheckCircleIcon style={{ color: "#059669" }} />
                <Typography
                  variant="body1"
                  style={{ color: "#001E62", marginLeft: 5 }}
                >
                  Password Successfully Changed!
                </Typography>
              </Box>
            }
          />
        </Snackbar>
        <Dialog
          maxWidth="md"
          open={isChangePasswordDialogOpen}
          PaperProps={{
            style: { borderBottomRightRadius: "32px", width: 460 },
          }}
          onClose={() =>
            updatePasswordType === "change" && handleChangePasswordDialogClose()
          }
        >
          {updatePasswordType === "change" && <IconButton
            edge="end"
            color="inherit"
            onClick={() => handleChangePasswordDialogClose()}
            aria-label="close"
            style={webStyle.closeIcon}
            data-test-id='closeIcon'
          >
            <CloseIcon />
          </IconButton>}
          <DialogTitle
            style={{ color: "#001E62", paddingBottom: 0 }}
          >
            {updatePasswordType === "change"
              ? "Change password"
              : "Create new password"}
          </DialogTitle>
          <DialogContent>
            <Typography style={{ fontSize: "1rem" }} color="primary">
              {updatePasswordType === "change"
                ? "You can update your password to keep your account secure"
                : "Create a new password"}
            </Typography>
            {updatePasswordType === "change" && (
              <>
                <Typography variant="body2" color="primary">
                  Old password
                </Typography>
                <TextField
                  data-test-id="oldPassword"
                  variant="outlined"
                  size="small"
                  color="primary"
                  placeholder="Old password"
                  type="Password"
                  fullWidth
                  value={oldPassword}
                  error={oldPasswordError!==''}
                  helperText={oldPasswordError}
                  onChange={(event) =>
                    this.setState({ oldPassword: event.target.value })
                  }
                />
              </>
            )}
            <Typography variant="body2" color="primary">
              New password
            </Typography>
            <TextField
              data-test-id="password"
              variant="outlined"
              size="small"
              color="primary"
              placeholder="Password"
              type="Password"
              fullWidth
              value={password}
              onChange={(event) =>
                this.handlePasswordChange(event.target.value)
              }
            />
            <Typography variant="body2" color="primary">
              Confirm password
            </Typography>
            <TextField
              data-test-id="confirmPassword"
              variant="outlined"
              size="small"
              color="primary"
              placeholder="Password"
              type="Password"
              fullWidth
              value={confirmedPassword}
              onChange={(e: any) =>
                this.handleConfirmPasswordChange(e.target.value)
              }
              error={confirmPasswordError}
              helperText={confirmPasswordError && "Passwords do not match"}
            />
            <Box style={{ marginTop: 25 }}>
              <Typography style={{ fontSize: "1rem" }} color="primary">
                Your password must contain
              </Typography>
              <Box style={webStyle.passwordCheckContainer}>
                <CheckCircleIcon
                  style={{
                    color: hasUppercase ? "#059669" : "#757575",
                    ...webStyle.checkIcon,
                  }}
                />
                <Typography variant="subtitle1" color="primary">
                  At least one capital letter
                </Typography>
              </Box>
              <Box style={webStyle.passwordCheckContainer}>
                <CheckCircleIcon
                  style={{
                    color: hasLowercase ? "#059669" : "#757575",
                    ...webStyle.checkIcon,
                  }}
                />
                <Typography variant="subtitle1" color="primary">
                  At least one lowercase letter
                </Typography>
              </Box>
              <Box style={webStyle.passwordCheckContainer}>
                <CheckCircleIcon
                  style={{
                    color: hasDigit ? "#059669" : "#757575",
                    ...webStyle.checkIcon,
                  }}
                />
                <Typography variant="subtitle1" color="primary">
                  At least one number
                </Typography>
              </Box>
              <Box style={webStyle.passwordCheckContainer}>
                <CheckCircleIcon
                  style={{
                    color: validlength ? "#059669" : "#757575",
                    ...webStyle.checkIcon,
                  }}
                />
                <Typography variant="subtitle1" color="primary">
                  Minimum character length is 8 characters
                </Typography>
              </Box>
            </Box>
          </DialogContent>
          <DialogActions style={{ borderTop: "1px solid #757575" }}>
            <Button
              style={{
                margin: 15,
                height: 50,
                borderRadius: 10,
                backgroundColor: "#00C3E6",
                color: "#fff",
                textTransform: "none",
                opacity:this.checkDisabled() ? '40%' : '100%'
              }}
              disabled={this.checkDisabled()}
              onClick={() => {
                updatePasswordType === "reset"
                  ? this.updatePasswordAPICall()
                  : this.changePasswordAPICall()
              }}
              data-test-id="changePasswordButton"
            >
              Change password
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

const webStyle = {
  passwordCheckContainer: {
    display: "flex",
    alignItems: "center",
  },
  checkIcon: {
    marginRight: 5,
    height: 15,
    width: 15,
  },
  closeIcon: {
    margin: 0,
    marginLeft: "auto",
  },
};
