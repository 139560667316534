import React from "react";

// Customizable Area Start
import { Card, CardContent, Box, TextField, MenuItem, Grid } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {PdfObject} from './PDFPreviewController.web'
// Customizable Area End

import PDFPreviewController, { Props } from "./PDFPreviewController.web";

export default class PDFPreview extends PDFPreviewController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Card
          variant="elevation"
          elevation={0}
          style={{
            ...webStyle.cardStyle,
            overflowY: "scroll",
            scrollbarWidth: "none",
          }}
        >
          <CardContent style={{ padding: 0 }}>
            <Box padding={1}>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <TextField
                    data-test-id={`selectPdf`}
                    select
                    variant="outlined"
                    size="small"
                    label="Select Document"
                    fullWidth
                    value={this.state.selectedPdf}
                    onChange={(e) =>
                      this.handlePdfSelect(e)
                    }
                  >
                    {this.props.pdfURL.map((url: PdfObject) => (
                      <MenuItem key={url.id} value={url.url}>
                        {url.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </Box>
            {
              this.checkFileType(this.state.selectedPdf) === 'pdf' ?
              <iframe width='100%' height='100%' src={this.state.selectedPdf}/> :
              <img width='100%' height='100%' src={this.state.selectedPdf}/> 
            }
          </CardContent>
        </Card>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#001E62",
      contrastText: "#001E62",
    },
  },
});

const webStyle = {
  cardStyle: {
    backgroundColor: "#fff",
    borderRadius: 8,
    width: "100%",
    height: "90vh",
  },
};
// Customizable Area End
