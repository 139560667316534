import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  email: string;
  password: any;
  reTypePassword: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  validlength:boolean;
  hasUppercase: boolean;
  hasLowercase: boolean;
  hasDigit: boolean;
  confirmPasswordError:boolean;
  screenContentType:string;
  passwordUpdateError:string
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class NewPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  resetPasswordAPICallID: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      email: "",
      password: "",
      reTypePassword: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      validlength: false,
      hasUppercase: false,
      hasLowercase: false,
      hasDigit: false,
      confirmPasswordError:false,
      screenContentType:'newPassword',
      passwordUpdateError:''
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    let email = localStorage.getItem('email')
    if(email) this.setState({email:email})
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.restAPIReceive(message);
    // Customizable Area End
  }

  // Customizable Area Start

  restAPIReceive = (message: any) => {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        this.APIResponse(apiRequestCallId, responseJson);
      }
    }
  };

  APIResponse = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.resetPasswordAPICallID) {
      if (!responseJson.error) {
        this.setState({
          screenContentType:'passwordChanged'
        });
      } else {
        //Check Error Response
        this.NewPasswordErrorHandler(responseJson.error);
      }
    }
  };

  NewPasswordErrorHandler = (error:string) => {
    this.setState({
      passwordUpdateError:error
    })
  }

  passwordReset = async () => {
    let pathname = window.location.pathname
    let pathnameArray = pathname.split('/')
    let token = pathnameArray[pathnameArray.length-1]
    let formdata = new FormData();
    formdata.append("reset_token", String(token));
    formdata.append("password", String(this.state.password));
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.resetPasswordAPICallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resetPassword
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({})
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePatch
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handlePasswordChange = (value: string) => {
    this.setState({
      password: value,
      validlength: value.length >= 8,
      hasUppercase: /[A-Z]/.test(value),
      hasLowercase: /[a-z]/.test(value),
      hasDigit: /\d/.test(value),
    },() => this.checkPasswordError());
  };

  handleConfirmPasswordChange = (value:string) => {
    this.setState({ reTypePassword: value },() => this.checkPasswordError())
  }

  checkPasswordError = () => {
    const {password, reTypePassword} = this.state
    const passwordError = password !== "" && reTypePassword !== "" && password !== reTypePassword 
    this.setState({
      confirmPasswordError:passwordError
    })
  }

  handleBackToLoginClick = () => {
    const msg: Message = new Message(getName(MessageEnum.NavigateEmailSignUpMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
    this.send(msg);
  }

  checkDisabled = () => {
    const { validlength, hasUppercase, hasLowercase, hasDigit, password, reTypePassword} = this.state
    return (!validlength ||
    !hasUppercase ||
    !hasLowercase ||
    !hasDigit ||
    password !== reTypePassword)
  }
  // Customizable Area End
}
