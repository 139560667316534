import React from "react";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Grid, Typography, Box, TextField, Avatar, Button } from "@material-ui/core";
const theme = createTheme({
  palette: {
    primary: {
      main: "#001E62",
      contrastText: "#001E62",
    },
  },
  typography: {
    h5: {
      fontWeight: 700,
    },
    body2: {
      fontWeight: 600,
      margin: "10px 0px",
      marginTop: 25,
    },
    subtitle1: {
      fontSize: "0.8rem",
    },
    fontFamily: "Graphik",
  },
  overrides:{
    MuiButton:{
      root:{
        '&:hover':{
          backgroundColor:'transparent',
          boxShadow:'none'
        }
      }
    }
  }
});
// Customizable Area End

import ProfileSettingController from "./ProfieSettingController";
export default class ProfileSetting extends ProfileSettingController {
  // Customizable Area Start
  // Customizable Area End

  render() {
    const {fullName, userEmail, userPosition, userDepartment, userAvatar, tempProfileImage, uploadedProfileImage} = this.state
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Typography color="primary" variant="h5">
          My Account
        </Typography>
        <Grid
          container
          justifyContent="center"
          style={{ padding: 20, height: "calc(100% - 47px)" }}
        >
          <Grid item md={10} container>
            <Grid
              item
              xs={12}
              md={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Avatar
                src={tempProfileImage || userAvatar}
                alt="profile image"
                style={{
                  borderRadius: "50%",
                  height: 250,
                  width: 250,
                  border: "4px solid #f0e5ff",
                }}
              />
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  width: "50%",
                  marginTop: 20,
                }}
              >
              <Button 
                disableRipple
                variant="text" 
                style={{ color: "#757575", textTransform:'none' }}
                onClick={() => this.removeUserProfileAPICall()}
                data-test-id='deleteProfileButton'
              >
                Delete
              </Button>
              <Button
                component='label'
                disableRipple
                variant="text"
                style={{ color: "#00c3e6", textTransform:'none' }}
                data-test-id='updateProfileButton'
              >
                Update
                <input 
                  data-test-id='profileImage'
                  type="file" 
                  accept="image/*"
                  style={{display:'none'}} 
                  onChange={(e) => this.handleProfileImageInput(e.target.files)}
                />
              </Button>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Button 
                  disableRipple
                  variant="text" 
                  style={{ color: "#757575", textTransform:'none' }}
                  onClick={() => this.getUserDetailsAPICall()}
                  data-test-id='profileSettingCancelButton'
                >
                  Cancel
                </Button>
                <Button
                  disableRipple
                  variant="text"
                  style={{ color: "#00c3e6", marginLeft: 10, textTransform:'none' }}
                  onClick={() => this.updateUserDetailsAPICall()}
                  data-test-id='saveButton'
                  disabled={
                    fullName === '' ||
                    userPosition === '' ||
                    userDepartment === ''
                  }
                >
                  Save
                </Button>
              </Box>
              <Typography
                variant="h6"
                color="primary"
                style={{ marginTop: 30, marginBottom: 20 }}
              >
                Personal details
              </Typography>
              <Typography variant="body2" color="primary">
                Full name
              </Typography>
              <TextField
                data-test-id="fullName"
                variant="outlined"
                size="small"
                color="primary"
                placeholder="Full name"
                type="text"
                fullWidth
                value={fullName}
                error={fullName === ''}
                helperText={!fullName && 'Full name is mandatory'}
                onChange={(e) => this.setState({fullName:e.target.value})}
              />
              <Typography variant="body2" color="primary">
                Email
              </Typography>
              <TextField
                data-test-id="email"
                variant="outlined"
                size="small"
                color="primary"
                placeholder="Email"
                type="text"
                fullWidth
                value={userEmail}
                disabled
              />
              <Typography variant="body2" color="primary">
                Position
              </Typography>
              <TextField
                data-test-id="position"
                variant="outlined"
                size="small"
                color="primary"
                placeholder="Position"
                type="text"
                fullWidth
                value={userPosition}
                error={userPosition === ''}
                helperText={!userPosition && 'Position is mandatory'}
                onChange={(e) => this.setState({userPosition:e.target.value})}
              />
              <Typography variant="body2" color="primary">
                Department
              </Typography>
              <TextField
                data-test-id="department"
                variant="outlined"
                size="small"
                color="primary"
                placeholder="Department"
                type="text"
                fullWidth
                value={userDepartment}
                error={userDepartment === ''}
                helperText={!userDepartment && 'Department is mandatory'}
                onChange={(e) => this.setState({userDepartment:e.target.value})}
              />
              <Box style={{ display: "flex", justifyContent: "space-between" }}>
                <Box>
                  <Typography variant="body2" color="primary">
                    Password
                  </Typography>
                  <Typography
                    variant="body2"
                    color="primary"
                    style={{ margin: 0 }}
                  >
                    ********
                  </Typography>
                </Box>
                <Typography
                  data-test-id="changePasswordDialogButton"
                  variant="body2"
                  style={{ color: "#00c3e6", cursor:'pointer' }}
                  onClick={() => this.props.handlePasswordChangeClick()}
                >
                  Change password
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
