import React, { useContext } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { SnackbarLoaderContext } from "./context";

const SnackbarMessage = () => {
  const context = useContext(SnackbarLoaderContext);
  const { isSnackbarOpen, snackbarMessage, unsetSnackbar } = context;

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={isSnackbarOpen}
      onClose={unsetSnackbar}
      autoHideDuration={6000}
      message={snackbarMessage}
      action={
        //@ts-ignore
        <IconButton size="small" color="inherit" onClick={unsetSnackbar}>
          <CloseIcon fontSize="small" />
        </IconButton>
      }
    />
  );
};

export default SnackbarMessage;
