// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';

import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';

import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import Cffetchdetailsfrominvoicetemplate4 from '../../blocks/cffetchdetailsfrominvoicetemplate4/src/Cffetchdetailsfrominvoicetemplate4';
import Tasks from '../../blocks/tasks/src/Tasks';
import TaskList from '../../blocks/tasks/src/TaskList';
import Task from '../../blocks/tasks/src/Task';
import PhoneNumberInput from '../../blocks/mobile-account-registration/src/PhoneNumberInput';
import AdditionalDetailForm from '../../blocks/mobile-account-registration/src/AdditionalDetailForm';
import AdvancedSearch from '../../blocks/advancedsearch/src/AdvancedSearch';
import Emailnotifications2 from '../../blocks/emailnotifications2/src/Emailnotifications2';
import Customform3 from '../../blocks/customform3/src/Customform3';
import OTPInputAuth from '../../blocks/otp-input-confirmation/src/OTPInputAuth';
import LanguageSupport from '../../blocks/languagesupport/src/LanguageSupport';
import VisualAnalytics from '../../blocks/visualanalytics/src/VisualAnalytics';
import Adminconsole2 from '../../blocks/adminconsole2/src/Adminconsole2';
import Cfintegrationwithoraclenetsuite2 from '../../blocks/cfintegrationwithoraclenetsuite2/src/Cfintegrationwithoraclenetsuite2';
import Formapprovalworkflow from '../../blocks/formapprovalworkflow/src/Formapprovalworkflow';
import CountryCodeSelector from '../../blocks/country-code-selector/src/CountryCodeSelector';
import AccountGroups from '../../blocks/accountgroups/src/AccountGroups';
import Settings2 from '../../blocks/settings2/src/Settings2';
import Rolesandpermissions from '../../blocks/rolesandpermissions/src/Rolesandpermissions';
import DocumentOpener from '../../blocks/documentopener/src/DocumentOpener';
import Cffetchdetailsfrominvoicetemplate from '../../blocks/cffetchdetailsfrominvoicetemplate/src/Cffetchdetailsfrominvoicetemplate';
import SocialMediaAccountRegistrationScreen from '../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen';
import Dashboard from '../../blocks/dashboard/src/Dashboard.web';
import Ocropticalcharacterrecognition from '../../blocks/ocropticalcharacterrecognition/src/Ocropticalcharacterrecognition';
import ImportExportData from '../../blocks/importexportdata/src/ImportExportData';
import Uploadmedia3 from '../../blocks/uploadmedia3/src/Uploadmedia3';
import LandingPage from '../../blocks/landingpage/src/LandingPage';
import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistration.web';
import Cffetchdetailsfrominvoicetemplate22 from '../../blocks/cffetchdetailsfrominvoicetemplate22/src/Cffetchdetailsfrominvoicetemplate22';
import Categoriessubcategories from '../../blocks/categoriessubcategories/src/Categoriessubcategories';
import Cffetchdetailsfrominvoicetemplate32 from '../../blocks/cffetchdetailsfrominvoicetemplate32/src/Cffetchdetailsfrominvoicetemplate32';
import NewPassword from '../../blocks/email-account-registration/src/NewPassword.web';
// import { SnackbarMessageProvider } from '../../components/src/SnackBar.web';
// import Loader from "../../components/src/Loader.web"
import ContextProvider from '../../components/src/context';

const routeMap = {
  Cffetchdetailsfrominvoicetemplate4: {
    component: Cffetchdetailsfrominvoicetemplate4,
    path: '/Cffetchdetailsfrominvoicetemplate4'
  },
  Tasks: {
    component: Tasks,
    path: '/Tasks'
  },
  TaskList: {
    component: TaskList,
    path: '/TaskList'
  },
  Task: {
    component: Task,
    path: '/Task'
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: '/PhoneNumberInput'
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: '/AdditionalDetailForm'
  },
  AdvancedSearch: {
    component: AdvancedSearch,
    path: '/AdvancedSearch'
  },
  Emailnotifications2: {
    component: Emailnotifications2,
    path: '/Emailnotifications2'
  },
  Customform3: {
    component: Customform3,
    path: '/Customform3'
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: '/OTPInputAuth'
  },
  LanguageSupport: {
    component: LanguageSupport,
    path: '/LanguageSupport'
  },
  VisualAnalytics: {
    component: VisualAnalytics,
    path: '/VisualAnalytics'
  },
  Adminconsole2: {
    component: Adminconsole2,
    path: '/Adminconsole2'
  },
  Cfintegrationwithoraclenetsuite2: {
    component: Cfintegrationwithoraclenetsuite2,
    path: '/Cfintegrationwithoraclenetsuite2'
  },
  Formapprovalworkflow: {
    component: Formapprovalworkflow,
    path: '/Formapprovalworkflow'
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: '/CountryCodeSelector'
  },
  AccountGroups: {
    component: AccountGroups,
    path: '/AccountGroups'
  },
  Settings2: {
    component: Settings2,
    path: '/Settings2'
  },
  Rolesandpermissions: {
    component: Rolesandpermissions,
    path: '/Rolesandpermissions'
  },
  Cffetchdetailsfrominvoicetemplate: {
    component: Cffetchdetailsfrominvoicetemplate,
    path: '/Cffetchdetailsfrominvoicetemplate'
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: '/SocialMediaAccountRegistrationScreen'
  },
  Dashboard: {
    component: Dashboard,
    path: '/Dashboard'
  },
  Ocropticalcharacterrecognition: {
    component: Ocropticalcharacterrecognition,
    path: '/Ocropticalcharacterrecognition'
  },
  ImportExportData: {
    component: ImportExportData,
    path: '/ImportExportData'
  },
  Uploadmedia3: {
    component: Uploadmedia3,
    path: '/Uploadmedia3'
  },
  LandingPage: {
    component: LandingPage,
    path: '/',
    exact: true
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: '/login',
    // path:"/",
    exact: true
  },
  ResetPassword: {
    component: NewPassword,
    path: '/reset-password'
  },
  Cffetchdetailsfrominvoicetemplate22: {
    component: Cffetchdetailsfrominvoicetemplate22,
    path: '/Cffetchdetailsfrominvoicetemplate22'
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: '/Categoriessubcategories'
  },
  Cffetchdetailsfrominvoicetemplate32: {
    component: Cffetchdetailsfrominvoicetemplate32,
    path: '/Cffetchdetailsfrominvoicetemplate32'
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true
  }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3'
});

class App extends Component {
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        {/* <TopNav /> */}
          {/* <Loader> */}
        <ContextProvider>
            {WebRoutesGenerator({ routeMap })}
        </ContextProvider>
          {/* </Loader> */}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
