import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import {SnackbarLoaderContext} from "../../../components/src/context"
interface UserData {
  "data": {
      "id": string,
      "type": string,
      "attributes": {
          "activated": true,
          "first_login": false,
          "country_code": string,
          "email": string,
          "first_name": string,
          "last_name": string,
          "full_name": string,
          "full_phone_number": string,
          "phone_number": string,
          "type": string,
          "created_at": string,
          "updated_at": string,
          "device_id": null,
          "unique_auth_id": string,
          "position": string,
          "department": string,
          "avatar":string
      }
  },
  "errors": [
    {
        "token": string
    }
]
}
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handlePasswordChangeClick: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  fullName:string;
  userEmail:string;
  userPosition:string;
  userDepartment:string;
  userAvatar:string;
  tempProfileImage:any;
  uploadedProfileImage:string;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class ProfileSettingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getUserDetailsAPICallId: string = "";
  updateUserDetailsAPICallId: string = "";
  updateUserProfileAPICallId: string = "";
  removeUserProfileAPICallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      fullName:"",
      userEmail:"",
      userPosition:"",
      userDepartment:"",
      userAvatar:"",
      tempProfileImage:"",
      uploadedProfileImage:""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getUserDetailsAPICall()
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.receivedata(message)
    // Customizable Area End
  }
  // Customizable Area Start
  static contextType = SnackbarLoaderContext

  receivedata = (message: any) => {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        this.apiResponseHandller(apiRequestCallId, responseJson);
      }
    }
  };

  apiResponseHandller = (apiRequestCallId: any, responseJson: UserData) => {
    this.context.unsetLoader && this.context.unsetLoader()
    if (apiRequestCallId === this.getUserDetailsAPICallId) {
      if(!responseJson.errors){
        const {full_name, email, position, department, avatar} = responseJson.data.attributes
        this.setState({
          uploadedProfileImage:"",
          tempProfileImage:"",
          fullName:full_name,
          userEmail:email,
          userPosition:position,
          userDepartment:department,
          userAvatar:avatar
        })
      }
    } else if (apiRequestCallId === this.updateUserProfileAPICallId) {
      if(!responseJson.errors){
        this.handleSetSnackbar("User profile updated successfully!")
        this.getUserDetailsAPICall()
      }
    } else if (apiRequestCallId === this.removeUserProfileAPICallId) {
      if(!responseJson.errors){
        this.handleSetSnackbar("User profile deleted successfully!")
        this.getUserDetailsAPICall()
      }
    } else if (apiRequestCallId === this.updateUserDetailsAPICallId) {
      if(!responseJson.errors){
        this.handleSetSnackbar("User data updated successfully!")
        this.getUserDetailsAPICall()
      }
    }
  };

  getUserDetailsAPICall = () => {        
    this.handleSetLoader()
    const header = {
      token: localStorage.getItem('token'),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUserDetailsAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getUserDetails
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  updateUserDetailsAPICall = () => {
    this.handleSetLoader()
    const {fullName, userPosition, userDepartment} = this.state
    const header = {
      token: localStorage.getItem('token'),
    };
    let formdata = new FormData();
    formdata.append("account[full_name]", fullName);
    formdata.append("account[position]", userPosition);
    formdata.append("account[department]", userDepartment);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateUserDetailsAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateUserDetails
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodTypeGetPut
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  updateUserProfileAPICall = (profileImage:File) => {
    this.handleSetLoader()
    const header = {
      token: localStorage.getItem('token'),
    };
    let formdata = new FormData();
    formdata.append("avatar", profileImage);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateUserProfileAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateUserProfile
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodTypeGetPatch
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  removeUserProfileAPICall = () => {
    this.handleSetLoader()
    const header = {
      token: localStorage.getItem('token'),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.removeUserProfileAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.removeUserProfile
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodTypeGetDelete
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleProfileImageInput = (files:any) => {
    const file = files[0]
    this.updateUserProfileAPICall(file)
  }

  handleSetLoader = () => {
    this.context.setLoader &&  this.context.setLoader()
  }

  handleSetSnackbar = (message:string) => {
    this.context.setSnackbar && this.context.setSnackbar(message)
  }
  // Customizable Area End
}
