import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import {SnackbarLoaderContext} from "../../../components/src/context"
import StorageProvider from "framework/src/StorageProvider.web";
import { apiCalling } from "../../../components/src/Constant";
export interface UploadHistoryType{
  id: string,
  type: string,
  attributes: {
      account_id: number,
      status: string,
      created_at: string,
      updated_at: string,
      bill_type_name: string,
      size: string,
      name: string,
      "netsuite_sync"?: string,
  }
}
export interface ResyncDocApiResponseJsontype {
  message: string;
}

// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  uploadHistoryTabType: number;
  searchQuery:string;
  uploadHistoryData:UploadHistoryType[];
  filteredUploadHistoryData:UploadHistoryType[];
  isToastOpen: boolean,
  ToatstMsg: string
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class UploadHistoryController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getUploadHistoryDataAPICallId:string = "";
  postResyncDocumentAPICallId:string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      uploadHistoryTabType: 0,
      searchQuery:"",
      uploadHistoryData: [],
      filteredUploadHistoryData: [],
      isToastOpen: false,
      ToatstMsg: ""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getUploadHistoryDataAPICallIdAPICall()
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.receiveUploadHistory(message)
    // Customizable Area End
  }
  // Customizable Area Start
  static contextType = SnackbarLoaderContext

  receiveUploadHistory = (message: any) => {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        this.apiResponseHandller(apiRequestCallId, responseJson);
      }
    }
  };

  apiResponseHandller = (apiRequestCallId: any, responseJson: any) => {
    this.context.unsetLoader && this.context.unsetLoader()
    if (apiRequestCallId === this.getUploadHistoryDataAPICallId) {
      if (!responseJson.errors) {
        this.setState({
          uploadHistoryData:responseJson.data,
          filteredUploadHistoryData:responseJson.data,
        },this.updateFilteredData)
      }
    } else if ( apiRequestCallId === this.postResyncDocumentAPICallId) {
      this.handleResyncDocumentresponse(responseJson)
    }
  };

  handleResyncDocumentresponse = (responseJson : ResyncDocApiResponseJsontype) => {
     if( responseJson && responseJson.message) {
       this.setState({ isToastOpen: true, ToatstMsg: responseJson.message}, () => this.getUploadHistoryDataAPICallIdAPICall())
     } else {
        this.setState({ isToastOpen: false, ToatstMsg: "Something went wrong"})
     }
  }

  handleTabChange = (event: any, newValue: number) => {
    this.setState({
      uploadHistoryTabType: newValue
    }, this.getUploadHistoryDataAPICallIdAPICall);
  };

  updateFilteredData = () => {
    const { uploadHistoryTabType, searchQuery, uploadHistoryData } = this.state;
    const newFilteredData = this.filterData(uploadHistoryData, uploadHistoryTabType, searchQuery);
    this.setState({ filteredUploadHistoryData: newFilteredData });
  };

  handleSearchQueryChange = (searchQuery:string) => {
    this.setState({ searchQuery }, this.updateFilteredData);
  }

  filterData = (data:UploadHistoryType[], selectedTab:number, searchQuery:string) => {
    return data.filter((item:UploadHistoryType) => {
      const statusCondition =
        selectedTab === 0 ? item.attributes.status === "approved" :
        selectedTab === 1 ? item.attributes.status === "submitted" :
        selectedTab === 2 ? item.attributes.status === "rejected" :
        false;
      
      const searchCondition = 
        item.attributes.bill_type_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.attributes.name.toLowerCase().includes(searchQuery.toLowerCase());
  
      return statusCondition && (searchQuery === '' || searchCondition);
    });
  }

  getUploadHistoryDataAPICallIdAPICall = async () => {        
    this.handleSetLoader()
    const header = {
      token: await StorageProvider.get("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUploadHistoryDataAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.uploadHistory
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  resyncDocument = async (docID: string) => {
    this.handleSetLoader()
    const header = {
      token: await StorageProvider.get("token"),
    };

    this.postResyncDocumentAPICallId = apiCalling({
      header: JSON.stringify(header),
      method:configJSON.postTypeApiRequest,
      endPoint: `${configJSON.resyncDocumentApiCallEndpoint}?id=${docID}`
    })
    
  }

  handleCloseAlert = () => {
    this.setState({ isToastOpen : false})
  }

  handleSetLoader = () => {
    this.context.setLoader &&  this.context.setLoader()
  }
  // Customizable Area End
}
